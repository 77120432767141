<template>
  <!-- transparent rounded div container -->
  <Transition>
    <div v-if="semeaphores.scrolled" class="ml">
      <v-btn class="button" @click="changeLanguage()">
        <p v-if="switchi" class="languageColorDE">DE</p>
        <p v-else class="languageColor">EN</p>
      </v-btn>
    </div>
  </Transition>
</template>

<script>
export default {
  name: "MultiLanguage",
  props: { semeaphores: Object },
  data() {
    return {
      switchi: true,
    };
  },
  methods: {
    changeLanguage() {
      this.switchi = !this.switchi;
      if (this.switchi) {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "ger";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button {
  transition: all 0.5s ease;
  border-bottom: 2px solid #eee;
  border-top: 2px solid #eee;

  color: #eee;
  background-color: transparent;
  color: white;
  padding: 2px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}
.languageColor {
  color: #a6e59b;
  position: relative;
  font-size: 10px;
}

.languageColorDE {
  color: #a6e59b;
  position: relative;
  font-size: 10px;
}
.ml {
  position: fixed;
  left: 95%;
  top: 4%;
  margin-right: 10px;
  transform: translate(-50%, 0%);
  color: #eee;
  z-index: 1000;
  /* -moz-border-radius:10px;
  -webkit-border-radius:10px;
  border-radius:10px;
  background-color: rgba(255,255,255,0.2); 
  border-color: rgba(255,255,255,0.2);*/
}

.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
