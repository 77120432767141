
import {
    ShaderMaterial,
    Vector2,
    Points,
    BufferAttribute,
    Color,
    AdditiveBlending,
    PlaneGeometry,
    Mesh,
    DoubleSide,
    MeshBasicMaterial,
    TextureLoader
} from "three";



import vertex from "./GLS/vertexShowReel.glsl";
import fragment from "./GLS/fragmentShowReel.glsl";


class ShowReel {
    constructor(settings, scene, loop, particleStart) {
        let images = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

        this.index = 0;
        this.indexIntern = 0;
        this.alpha = 1;

        this.materials = [];
        this.amount = images.length;

        for (let i = 0; i < images.length; i++) {
            let geo = new PlaneGeometry(140, 300);

            let t = "/projects/" + (i + 1) + ".png";
            const texture = new TextureLoader().load(t);
            this.hide = i == 2;
            this.materials[i] = new ShaderMaterial({
                uniforms: {
                    // time: { value: 0. },
                    uResolution: { value: new Vector2() },
                    uI: { type: "f", value: i },
                    uIndex: { type: "f", value: this.indexIntern },
                    uHide: { type: "b", value: this.hide },
                    uImageAmount: { type: "f", value: this.amount },
                    alpha: { type: "f", value: this.alpha },
                    time: { type: "f", value: 0 },
                    uTexture: { type: "t", value: texture },
                },
                // add shaders
                vertexShader: vertex,
                fragmentShader: fragment,

                // add other material properties
                depthTest: true,
                transparent: true,
                wireframe: false,
                depthWrite: true,
                // blending: AdditiveBlending,
            });

            let mirror = new Mesh(geo, this.materials[i])
            mirror.tick = (delta) => {
                this.materials[i].uniforms.uResolution.value.x = window.innerWidth;
                this.materials[i].uniforms.uResolution.value.y = window.innerHeight;
                if (this.index == 9 && this.indexIntern == 0) {
                    this.indexIntern = Math.max(this.index, (10 - delta))
                }
                else if (this.index == 0 && this.indexIntern == 9) {
                    this.indexIntern = Math.min(this.index, (-1 + delta))
                }
                else if (this.index < this.indexIntern) {
                    this.indexIntern = Math.max(this.index, (this.indexIntern - delta))
                }
                else if (this.index > this.indexIntern) {
                    this.indexIntern = Math.min(this.index, (this.indexIntern + delta))
                } else {
                    this.indexIntern = this.index;
                }

                this.materials[i].uniforms.uI.value = (this.indexIntern + i) % this.amount;
                this.materials[i].uniforms.alpha.value = this.alpha;
                this.materials[i].uniforms.time.value += delta;
            };
            loop.updatables.push(mirror);
            scene.add(mirror);
        }
    }


}
export { ShowReel };



