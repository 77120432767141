
import {DDDWorld} from "./DDDWorld.js";
import {Lines} from "./Lines.js";


class Backend {
    
    constructor(settings, scene, loop, particleStart) {
        this.world = new DDDWorld(settings, scene, loop, particleStart);
        this.lines = new Lines(settings, scene, loop, particleStart);
    }


    
    updatePercentage(percentage) {
        this.world.percentage = percentage;
    }


    move(away) {
        this.world.updatePostion(away)
        this.lines.updatePostion(away)
    }

    startRequest() {
        this.lines.startRequest();
    }

    finishRequest(ix) {
        this.lines.finishRequest(ix);
    }

    clearRequest() {
        this.lines.clearRequest();
    }

    setBackend(backend) {
        this.world.setBackend(backend);
    }
}
export  { Backend };
