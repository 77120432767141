<template>
  <v-btn @click="$emit('reset')" :class="isMobile ? 'backButtonMobile' : 'backButton'">Zurück</v-btn>

  <div :class="isMobile ? 'legalMobile' : 'legal'">
    <h1 class="title">IMPRESSUM</h1>
    <h2 class="distance">Angaben gemäß § 5 TMG:</h2>
    <p class="text">Robin Haensse</p>
    <p class="text">Freiberuflicher Softwareenwickler und Berater</p>

    <h2 class="distance">Postanschrift:</h2>
    <p class="text">Robin Haensse</p>
    <p class="text">Kaiser-Joseph-Str. 254</p>
    <p class="text">79098 Freiburg</p>

    <h2 class="distance">Kontakt:</h2>
    <p class="text">Telefon: +49 175 9959692</p>
    <p class="text">E-Mail: hallo@haensse.dev</p>

    <h2 class="distance">Vertreten durch:</h2>
    Robin Haensse
    <!-- 
        <h2 class="distance">Umsatzsteuer-Identifikationsnummer:</h2>
        <p class="text">TBA</p>
    -->
    <h2 class="distance">Open Source Lizensen</h2>
    <p>
      Smartphone in "Work" <br />
      This work is based on "Smartphone" (https://sketchfab.com/3d-models/smartphone-380280333c9f4fb8a21a53d18f6789e6) by Manuel W.
      (https://sketchfab.com/nebulariser) licensed under CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
    </p>

    <p>
      Vite Logo <br />
      https://github.com/vitejs/vite/blob/main/docs/public/logo.svg by yyx990803 licensed by CC BY-SA 4-0 https://creativecommons.org/licenses/by-sa/4.0/deed.en
    </p>
    <p>
      ThreeJs Logo <br />
      https://commons.wikimedia.org/wiki/File:Three.js_Icon.svg done by Mstrdoob licensed by CC BY-SA 4-0 https://creativecommons.org/licenses/by-sa/4.0/deed.en
    </p>
    <p>
      Puppeteer Logo <br />https://iconduck.com/icons/94783/puppeteer, open source CC0 license by Stack Icons and can be found at
      https://github.com/puppeteer/puppeteer
    </p>
  </div>
</template>

<script>
export default {
  name: "Legal",
  props: {
    isMobile: Boolean,
  },
  data() {
    return {};
  },

  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.backButton {
  position: fixed;
  top: 40%;
  left: 12.5%;

  border: 3px solid #eee;
  color: #eee;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.7vw;
  cursor: pointer;
}

.backButtonMobile {
  position: fixed;
  top: 40%;
  left: 2.5%;
  width: 1vw;
  border: 3px solid #eee;
  color: #eee;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 2vw;
  cursor: pointer;
}

.legal {
  position: relative;
  left: 25%;
  top: 5%;
  color: #eee;
  width: 50%;
  padding: 50px;
  background-color: #006d00b4;
}

.legalMobile {
  position: fixed;
  left: 23%;
  top: 15%;
  color: #eee;
  width: 72%;
  padding: 2vw;
  background-color: #006d00b4;
}

.distance {
  margin-top: 3vh;
  margin-bottom: 10px;
  font-size: 2vh;
}

.title {
  margin-top: 3vh;
  font-size: 3vh;
}

.text {
  font-size: 1.5vh;
}
</style>
