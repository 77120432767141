<template>
  <!-- transparent rounded div container -->
  <div class="title">
    <Transition name="slide-fade">
      <div>
        <p v-if="semaphores.start" class="header">HAENSSE.DEV</p>
        <p v-if="semaphores.start || (semaphores.contact && !isMobile)" class="name-header">
          {{ $t("title.fullstack") }} <span style="color: #a6e59b"> {{ $t("title.developer") }}</span> Robin <span style="color: #a6e59b">Haensse</span>
        </p>
        <p v-if="semaphores.contact && !isMobile" class="fade-from-left2">{{ $t("title.degreeB") }}</p>
        <p v-if="semaphores.contact && !isMobile" class="fade-from-left3">{{ $t("title.experience") }}</p>
      </div>
    </Transition>
    <!-- <p class="fade-from-left" style="left:3%">Der Unten text</p> -->
  </div>
</template>

<script>
export default {
  name: "Title",
  props: {
    semaphores: Object,
    isMobile: Boolean,
  },
  data() {
    return {};
  },

  mounted() {
    this.display = true;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  position: absolute;
  left: 0%;
  top: 10%;
  width: 100%;
  text-align: center;
  /* -moz-border-radius:10px;
  -webkit-border-radius:10px;
  border-radius:10px;
  background-color: rgba(255,255,255,0.2); 
  border-color: rgba(255,255,255,0.2);*/
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.name-header {
  font-size: 2.1vw;
  color: #eee;
  position: fixed;
  left: 11vw;
  top: 24%;
  font-family: "Audiowide", sans-serif;
  animation: fade-from-left 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.fade-from-left2 {
  font-family: "Roboto", sans-serif;
  font-size: 1.5vw;
  color: #eee;
  position: fixed;
  left: 12vw;
  top: 31%;
  animation: fade-from-left 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.fade-from-left3 {
  font-family: "Roboto", sans-serif;
  font-size: 1.5vw;
  color: #eee;
  position: fixed;
  left: 12vw;
  top: 36%;
  animation: fade-from-left 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

@keyframes fade-from-left {
  0% {
    opacity: 0.1;
    transform: translateX(-15%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.header {
  font-family: "Audiowide", sans-serif;
  font-size: 9vw;
  top: 11vw;

  margin-top: 0;
  left: 50%;
  transform: translate(-50%, -15%);
  color: #eee;
  position: absolute;
}

.portfolio {
  font-family: "Audiowide", sans-serif;
  font-size: 2vw;
  top: 22vw;

  margin-top: 0;
  left: 75%;
  transform: translate(-50%, -15%);
  color: #a6e59b;
  position: absolute;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  transition: all 0.3s ease-in;
}

.slide-fade-enter-from {
  opacity: 0;
  transform: translateX(-20px);
}
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
