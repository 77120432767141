<template>
  <div>
    <Transition name="content" appear>
      <div v-if="display" :class="isMobile ? 'logo-containerMobile' : 'logo-container'">
        <div :class="isMobile ? 'icon-containerMobile' : 'icon-container'">
          <v-icon v-if="icon !== undefined" 
          class="logoInv"




            >mdi-{{ icon }}</v-icon
          >

          <img
            v-if="icon === undefined && image !==undefined"
            rel="preload"
            :class="invertedColor ? 'logoInv' : 'logo'"
            :src="image.src"
            @load="() => (imgLoaded = true)"
          />
        </div>

        <p :class="isMobile ? 'whitefontMobile' : 'whitefont'">{{ title }}</p>
      </div>
    </Transition>

    <Transition name="line" appear>
      <div v-if="display" :class="inverted ? 'lineInverted' : 'line'"></div>
    </Transition>
  </div>
</template>
    
<script>
export default {
  name: "Logo",
  props: {
    title: String,
    src: String,
    display: Boolean,
    icon: String,
    inverted: Boolean,
    invertedColor: Boolean,
    isMobile: Boolean,

  },
  data() {
    return {
      image: undefined,
    };
  },
  mounted() {
    // preload image
    this.image = new Image();
    this.image.src = this.src;
  },
};
</script>
    
<style scoped>
.line {
  width: 22%;
  left: 40%;
  position: relative;
  font-weight: var(--font-semi);
  padding: 0.1rem 0.1rem;
  height: 1px;
  background: #29C929;
  transform: rotate(0deg);
  transform-origin: 0 0;
  z-index: 10;
  margin-bottom: 1vw;
}
.lineInverted {
  width: 22%;
  left: 62%;
  position: relative;
  font-weight: var(--font-semi);
  padding: 0.1rem 0.1rem;
  border-radius: 0.5rem;
  height: 1px;
  background: #29C929;
  transform: rotate(180deg);
  transform-origin: 0 0;
  z-index: 10;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-weight: var(--font-semi);
  padding: 1.5vh 2vw;
  width: 100%;

}
.logo-containerMobile {
  display: flex;
  justify-content: left;
  align-items: left;
  position: relative;
  font-weight: var(--font-semi);
  padding: 0.6rem 1rem;
}


.icon-container {
  width: 10%;
}

.icon-containerMobile {
  width: 8%;
  padding-right:5vw;
}


.line-enter-active,
.line-leave-active {
  transition: opacity 1s ease;
  transition: width 1s ease;
}

.line-enter-from,
.line-leave-to {
  opacity: 0;
  width: 0%;
}

.content-enter-active {
  transition: opacity 3s ease;
}

.content-leave-active {
  transition: opacity 1s ease;
}

.content-enter-from {
  opacity: 0;
}

.content-leave-to {
  opacity: 0;
}

.logo {
  position: relative;
  filter: grayscale(1);
  height:  1vw;
;
  /* max-height: 11px; */
  margin-top: 2%;
  margin-bottom: 5%;
  padding-right: 2.2vh;

}

.logoInv {
  position: relative;
  filter: grayscale(1) invert(1);
  height:  1vw;
  /* max-height: 11px; */
  margin-top: 2%;
  margin-bottom: 5%;
padding-right: 2.2vh;
}


.whitefont {
  color: #EEE;
  font-size: 2.5vh;
}



.whitefontMobile {
  color: #EEE;
  font-size: 3.5vw;
}


</style>
    