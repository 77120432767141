
import { createI18n } from 'vue-i18n'

// acces via {{ $t("X.Y.Z.W")}}
export default function I18nInit() {
  let messages = {
    en: {
      title: {
        developer: "developer",
        fullstack: "full stack",
        degreeB: "Bachelor degree in computer science",
        degreeM: "Master degree in media computer science",
        experience: "+7 Years Programming Experience"
      },
      menu: {
        start: "START",
        skills: "SKILLS",
        projects: "PROJECTS",
        contact: "CONTACT"
      },
      skills: {
        title: 'SKILLS',
        other: "OTHER",
        teamlead: "Teamlead",
        projectlead: "Projectlead",
        backendshow: "ping azure backends"
      },
      work: {
        title: "PROJECTS",
        visitPage: "visit Page",
        toolsUsed: "Tools used",
        whatIdid: "What I did",

        projects: {
          portfolio: {
            contracter: "done by myself",
            idid: "Backend, Frontend, Hosting and Design"
          },
          cloud: {
            title: "Datacloud for homeless people",
            idid: "Project lead, team lead, programming (Full Stack)",
            morePress: "Press",
            morePress2: "Press 2"
          },
          megapart: {
            title: "Page for online survey",
            idid: "Project lead, team lead, programming (Full Stack), survey design, evaluation"
          },
          unitheater: {
            idid: "Design, hosting"
          },
          mundwerk: {
            idid: "Design, hosting"
          },
          hechtzeit: {
            idid: "Project concept and lead, implementation (full stack) and operater interaction software for live shows",
            moreInfo: "Details (German)",
            moreAction: "Demo (German)",
            morePress: "Press"
          },
          blackforesttales: {
            idid: "Design, hosting",
          },
          hiwiAIS: {
            title: "Software for chair of Autonomous Intelligent Systems",
            contracter: "Freiburg university as a student assistant",
            idid: "Writing Test cases in Matlab, Implement Raytracer on GPU with CUDA, implement GUI for Robotercontrol",
          },
          hiwiSimu: {
            title: "Software for chair of simulation",
            contracter: "Freiburg university as a student assistant",
            idid: "Implement a repartitioning graph algorithm to accelerate molecular dynmic computations"
          },
          hiwiCogmod: {
            title: "Software for chair of cognitive computation",
            contracter: "Freiburg university as a student assistant",
            idid: "Renew and maintainance ACT-R (LISP) cognitive Models for relational reasoning"
          }
        }
      },
      contact: {
        title: "CONTACT",
        fullStack: "Full Stack Developer",
        writeMe: "Write me",
        gpdrText: " I have read and accept the {0}",
        gpdr: "privacy policy",
        send: "send",
        emailSentSuccess: "email has been sent",
        emailSentError: "something went wrong, please try again later",
        ruleName: "Name is required",
        ruleEmail: "Email is required",
        ruleEmail2: "E-mail must be valid",
        ruleText: "Text is required",
        ruleText2: "Text must be at least 20 characters long",
        gpdrhint: "You have to accept this",
        name: "name",
        email: "email",
        message: "message"
      },
      footer: {
        legal: "Terms",
        gpdr: "Privacy"
      }
    },
    ger: {
      title: {
        developer: "Entwickler",
        fullstack: "Fullstack",
        degreeB: "Bachelor in Informatik",
        degreeM: "Master in Medieninformatik",
        experience: "+7 Jahre Programmiererfahrung"
      },
      menu: {
        start: "START",
        skills: "FÄHIGKEITEN",
        projects: "PROJEKTE",
        contact: "KONTAKT"
      },
      skills: {
        title: 'FÄHIGKEITEN',
        other: "WEITERE",
        teamlead: "Teamführung",
        projectlead: "Projektleitung",
        backendshow: "ping Azure Backends"
      },
      work: {
        title: "PROJEKTE",
        visitPage: "Besuch die Seite",
        toolsUsed: "Tools benutzt",
        whatIdid: "Meine Aufgaben",
        
        projects: {
          portfolio: {
            contracter: "Eigenarbeit",
            idid: "Backend, Frontend, Hosting und Design"
          },
          cloud: {
            title: "Cloud für wohnungslose Menschen",
            idid: "Projektleitung, Teamleitung, Programmierung (Full Stack)",
            morePress: "Presse",
            morePress2: "Presse 2"
          },
          megapart: {
            title: "Seite für Onlineumfrage",
            idid: "Projektleitung, Teamleitung, Programmierung (Full Stack), Umfragendesign, Evaluation"
          },
          unitheater: {
            idid: "Design, Hosting"
          },
          mundwerk: {
            idid: "Design, Hosting"
          },
          hechtzeit: {
            idid: "Projektkonzept, Projektleitung, Implementation (Full stack) und Bedienung von Interaktionsoftware für Live Shows",
            moreInfo: "Details",
            moreAction: "Demo",
            morePress: "Presse"
          },
          blackforesttales: {
            idid: "Design, Hosting",
          },
          hiwiAIS: {
            title: "Software für den Lehrstuhl für autonome intelligente Systeme",
            contracter: "Universität Freiburg als studentische Hilfskraft",
            idid: "Testfälle in Matlab geschrieben, Raytracer auf GPU mit CUDA implementiert, GUI für Robotercontrol implementiert",
          },
          hiwiSimu: {
            title: "Software für den Lehrstuhl für Simulation",
            contracter: "Universität Freiburg als studentische Hilfskraft",
            idid: "Implementierung eines Algorithmus zur Repartitionierung von Graphen, um molekulare dynmische Berechnungen zu beschleunigen"
          },
          hiwiCogmod: {
            title: "Software für den Lehrstuhlcognitive computation",
            contracter: "Universität Freiburg als studentische Hilfskraft",
            idid: "Erneuerung und Pflege ACT-R (LISP) kognitive Modelle für relationale Argumentation"
          }
        }

      },

      contact: {
        title: "KONTAKT",
        fullStack: "Full Stack Entwickler",
        writeMe: "Schreib Sie mir",
        gpdrText: "Ich habe die {0} gelesen und stimme zu",
        gpdr: "Datenschutzerklärung",
        send: "Senden",
        emailSentSuccess: "Email wurde versendet",
        emailSentError: "Oh, da ging etwa schief, bitte versuche es später erneut",
        ruleName: "Ein Name wird benötigt",
        ruleEmail: "Eine Email wird benötigt",
        ruleEmail2: "Das sieht nicht nach einer Email aus",
        ruleText: "Eine Nachricht wird benötigt",
        ruleText2: "Die Nachricht sollte mindestens 20 Zeichen haben",
        gpdrhint: "Du musst diesen Haken setzen",
        name: "Name",
        email: "E-mail",
        message: "Nachricht"

      },

      footer: {
        legal: "Impressum",
        gpdr: "Datenschutz"
      }


    }
  };

  return createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages,
  })
}