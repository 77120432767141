import { PerspectiveCamera, Vector2, Vector3, Euler } from "three";
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';

class Camera {
  constructor(settings, fov, position, rotation) {



    this.camera = new PerspectiveCamera(
      fov, // fov = Field Of View
      window.innerWidth / window.innerHeight, // aspect ratio (dummy value)
      0.001, // near clipping plane
      1000000 // far clipping plane
    );

    // move the camera back so we can view the scene
    this.camera.position.set(position.x, position.y, position.z);
    this.camera.rotateX(rotation.x);
    this.camera.rotateY(rotation.y);
    this.camera.rotateZ(rotation.z);

    // camera.lookAt( 0,0,0);
    // paralax effect for the camera depending on the mouse position
    let mouse = new Vector2();

    this.camera.tick = (delta) => {
      if (delta || false) {
      }
    };
  }


  getCam() {
    return this.camera;
  }

  tweenCamera(targetPosition, targetRotation, duration) {

    var position = new Vector3().copy(this.camera.position);
    var rotation = new Euler().copy(this.camera.rotation);
    TWEEN.removeAll();

    var tweenRot = new TWEEN.Tween(rotation)
      .to(targetRotation, duration)
      .easing(TWEEN.Easing.Linear.None)
      .onUpdate((update) => {
        // this.camera.rotation.copy(targetRotation);
        this.camera.rotation.setFromVector3(new Vector3(update._x, update._y,update._z));

        //this.camera.setRotationFromEuler(new Euler(update.x, update.y, update.z))
      })
      .onComplete(() => {
        this.camera.rotation.copy(targetRotation);
        // this.camera.lookAt([0,0,0]);
      })
      .start(
      );
      console.log(tweenRot);
      
    var tweenPos = new TWEEN.Tween(position)
      .to(targetPosition, duration)
      .easing(TWEEN.Easing.Linear.None)
      .onUpdate((update) => {
        this.camera.position.copy(position);
        // this.camera.lookAt([0,0,0]);
      })
      .onComplete(() => {
        this.camera.position.copy(targetPosition);
        // this.camera.lookAt([0,0,0]);
      })
      .start(
      );

  }
}



export { Camera };
