
import {
    ShaderMaterial,
    Vector2,
    Points,
    BufferAttribute,
    Color,
    TextureLoader,
    PlaneGeometry,

    Mesh,
    AdditiveBlending,
} from "three";



import vertex from "./GLS/vertexSmartphone.glsl";
import fragment from "./GLS/fragmentSmartphone.glsl";

class SmartPhone {

    constructor(settings, scene, loop) {

        let t = "/projects/smartphone.png";
        const texture = new TextureLoader().load(t);


        let mesh = new PlaneGeometry(145, 300);



        this.alpha = 1;
        // rotate by 90 degrees
        // mesh.rotateX(Math.PI / 2);

        // move to the hand
        // mesh.translate(4.5, 0, 1.9);



        // simple red material

        let material = new ShaderMaterial({
            uniforms: {
                time: { value: 0. },
                resolution: { value: new Vector2() },
                uTexture: {type : "t", value : texture},
                //velocity: {type:"vec3", value:[1,2,3]},
                alpha: { type: "f", value: this.alpha },

            },
            // add shaders
            vertexShader: vertex,
            fragmentShader: fragment,

            // add other material properties
            depthTest: true,
            
            transparent: true,
                alphaTest: 1, 
            depthWrite: true,
            // blending: AdditiveBlending,
        });




        mesh.translate(0, 0, 25);


        // apply material to mesh

        // scale mesh

        // mesh = new BoxGeometry(10,10, 10, 100, 100, 100)
        //material = new MeshBasicMaterial({ color: 0x00ff00 });
        let pixel = new Mesh(mesh, material);


        pixel.tick = (delta) => {
            material.uniforms.alpha.value = this.alpha;       
        };

        loop.updatables.push(pixel);

        //  pixel.scale.set( 0.01, 0.01, 0.01 );
        scene.add(pixel)
        // scene.add(pixel);


    }



}






export { SmartPhone };

