import { WebGLRenderer } from "three";

function createRenderer() {
  const renderer = new WebGLRenderer({ antialias: false, alpha: true });

  // turn on the physically correct lighting model
  // renderer.physicallyCorrectLights = true;

  // disable the background

  
  return renderer;
}

export { createRenderer };
