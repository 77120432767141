import { Camera } from "./components/Camera.js";
import { createLights } from "./components/lights.js";
import { createScene } from "./components/scene.js";
import { createRenderer } from "./systems/renderer.js";
import { Loop } from "./systems/Loop.js";
import { Resizer } from "./systems/Resizer.js";
import { createControls } from "./systems/controls.js";
import { AxesHelper } from "three";
import { Robin } from "./components/objects/Robin.js";
import { BackgroundStars } from "./components/objects/BackgroundStars.js"



import {
  Vector3,
  Euler,
} from "three";




import { GUI } from 'dat.gui'

import { Frontend } from "./components/objects/Frontend.js"
import { Backend } from "./components/objects/Backend.js"



// These variables are module-scoped: we cannot access them
// from outside the module



class World {
  constructor(container, scrollDelta, isMobile) {

    // Instances of camera, scene, and renderer
    this.setting(false);

    let color2 = "#FFFFFF";
    let color3 = "#199409";

    this.scene = createScene(color2);
    this.scene2 = createScene(color3);

    this.scrollDelta = scrollDelta;
    this.isMobile = isMobile;

    this.showRealIndex = 0;
    const axesHelper = new AxesHelper(5);
    this.scene.add(axesHelper);

    this.scrollLevel = 0;
    this.scene.background = null;
    this.scene2.background = null;

    // default position  



  

    this.initCameraPositions();

    let startPos = this.startTargetCamPosition;
    let startRot = this.startTargetCamRotation;


    this.camera = new Camera(this.settings, 45, startPos, startRot);
    this.camera2 = new Camera(this.settings, 75, new Vector3(0, 0, 9), new Vector3(0, 0, 0));


    this.particleStart = { pos: new Vector3(0, 0, -3), rot: new Vector3(0, 0, 0) };

    this.renderer = createRenderer();




    this.loop = new Loop(this.camera.getCam(), this.camera2.getCam(), this.scene, this.scene2, this.renderer);
    this.bgS = new BackgroundStars(this.settings, this.scene2, this.loop);
    this.robin = new Robin(this.settings, this.scene2, this.loop);

    if(!this.isMobile) { 
    this.frontend = new Frontend(this.settings, this.scene, this.loop, this.particleStart);
    this.backend = new Backend(this.settings, this.scene, this.loop, this.particleStart, this.camera.getCam());
  }
    let color = "#FFFFFF";


    container.append(this.renderer.domElement);

    // Orbit Controls
    // const controls = createControls(this.camera.getCam(), this.renderer.domElement);

    // Light Instance, with optional light helper
    const { light, lightHelper } = createLights(color);
    if (lightHelper) {
      () => { };
    }

    // Random values for terrain vertices
    // We could do this on the terrain.js file,
    // but if we want to have a single random
    // number array for more than one terrain
    // instance, then we would be in trouble.
    const randomVals = [];
    for (let i = 0; i < 12675; i++) {
      randomVals.push(Math.random() - 0.66);
    }

    // Terrain Instance
    //let terrain = createTerrain(this.settings, this.camera, this.scene);

    // this.loop.updatables.push(controls);
    this.loop.updatables.push(light);
    // this.loop.updatables.push(terrain);

    this.scene.add(light);


    // Responsive handler
    const resizer = new Resizer(container, this.camera.getCam(), this.camera2.getCam(), this.renderer);
    resizer.onResize = () => {
      this.render();
    };
    this.start();
  }


  initCameraPositions() {
    this.startTargetCamPosition = new Vector3(0, 55, 35);
    this.startTargetCamRotation = new Euler(0, 0, 0);

    if (this.isMobile) {
      // smartphone 3d camera positions

      this.backendTargetCamPosition = new Vector3(-100, 10, 80);
      this.backendTargetCamRotation = new Euler(0, -0.4, 0.0);

      this.frontEndTargetCamPosition = new Vector3(+100, -55, 410);
      this.frontEndTargetCamRotation = new Euler(0, +0.4, 0);

      this.contactTargetCamPosition = new Vector3(0, 55, -400);
      this.contactTargetCamRotation = new Euler(-1.8, 0, 0);
    } else {
      // dekstop 3d Camera positions
      this.backendTargetCamPosition = new Vector3(-90, -10, 40);
      this.backendTargetCamRotation = new Euler(0.0, -0.3, 0.0);

      this.frontEndTargetCamPosition = new Vector3(+90, -35, 450);
      this.frontEndTargetCamRotation = new Euler(0, +0.3, 0);

      this.contactTargetCamPosition = new Vector3(0, 35, -400);
      this.contactTargetCamRotation = new Euler(-1.8, 0, 0);


    }

  }

  setIsMobile(isMobile) {
    this.isMobile = isMobile;
    this.initCameraPositions();
  }

  setBackend(backend) {
    this.backend.setBackend(backend);
  }

  startRequest() {
    this.backend.startRequest();
  }

  finishRequest(ix) {
    this.backend.finishRequest(ix);
  }



  workLeft() {
    if(this.showRealIndex == 0) {
      this.showRealIndex = 9;
      } else {
        this.showRealIndex = Math.max(0, this.showRealIndex - 1);

      }
    this.backend.move((this.showRealIndex+2) % 10);
    this.frontend.setIndex(this.showRealIndex)
    return this.showRealIndex;

  }

  workRight() {
    if(this.showRealIndex == 9) {
     this.showRealIndex = 0;
     } else {
      this.showRealIndex = Math.min(9, this.showRealIndex + 1);

     }
    this.backend.move((this.showRealIndex+2) % 10);
    this.frontend.setIndex(this.showRealIndex)

    return this.showRealIndex;
  }
  updateUpdateProgress(scrollPercentage, semeaphores) {
    // weired js NaN behavior... protected..
    if (isNaN(scrollPercentage)) {
      // console.log("i dont know why this happens..");
      scrollPercentage = 0;
    }
    let targetPos = undefined;
    let targetRot = undefined

    this.bgS.setScrollLevel(scrollPercentage);
    this.robin.setScrollLevel(scrollPercentage);

    if(this.isMobile) {
      return;
    }

      this.frontend.updateAlpha(Math.min(1, Math.max(0., (scrollPercentage - 0.33) * 3)));
      this.backend.updatePercentage(scrollPercentage);
  
    if(!semeaphores.skills) {
      this.backend.clearRequest();
    }
    if(semeaphores.skills || semeaphores.contact) {
      this.showRealIndex = 0;
      this.frontend.setIndex(this.showRealIndex);
      this.backend.move(2);
    }





    let sV = 0;

    if (scrollPercentage >= 0 && scrollPercentage <= this.scrollDelta) {
      sV = 0;
    } else if (scrollPercentage > (0.33 - this.scrollDelta) && scrollPercentage <= (0.33 + this.scrollDelta)) {
      sV = 0.33;
    }
    else if (scrollPercentage > (0.66 - this.scrollDelta) && scrollPercentage <= (0.66 + this.scrollDelta)) {
      sV = 0.66;
    }
    else if (scrollPercentage > (1 - this.scrollDelta)) {
      sV = 1;
    }
    else {
      sV = scrollPercentage;
    }

    if (scrollPercentage <= 0.33) {

      targetPos = new Vector3(
        this.startTargetCamPosition.x + (this.backendTargetCamPosition.x) * sV * 3,
        this.startTargetCamPosition.y + (this.backendTargetCamPosition.y) * sV * 3,
        this.startTargetCamPosition.z + (this.backendTargetCamPosition.z) * sV * 3
      )

      targetRot = new Euler(
        this.startTargetCamRotation.x + (this.backendTargetCamRotation.x) * sV * 3,
        this.startTargetCamRotation.y + (this.backendTargetCamRotation.y) * sV * 3,
        this.startTargetCamRotation.z + (this.backendTargetCamRotation.z) * sV * 3
      )
    }
    if (scrollPercentage > 0.33 && scrollPercentage <= 0.66) {
      targetPos = new Vector3(
        this.startTargetCamPosition.x + this.backendTargetCamPosition.x + (this.frontEndTargetCamPosition.x) * (sV - 0.33) * 3,
        this.startTargetCamPosition.y + this.backendTargetCamPosition.y + (this.frontEndTargetCamPosition.y) * (sV - 0.33) * 3,
        this.startTargetCamPosition.z + this.backendTargetCamPosition.z + (this.frontEndTargetCamPosition.z) * (sV - 0.33) * 3
      )

      targetRot = new Euler(
        this.startTargetCamRotation.x + this.backendTargetCamRotation.x + (this.frontEndTargetCamRotation.x) * (sV - 0.33) * 3,
        this.startTargetCamRotation.y + this.backendTargetCamRotation.y + (this.frontEndTargetCamRotation.y) * (sV - 0.33) * 3,
        this.startTargetCamRotation.z + this.backendTargetCamRotation.z + (this.frontEndTargetCamRotation.z) * (sV - 0.33) * 3
      )

    }
    if (scrollPercentage > 0.66) {
      targetPos = new Vector3(
        this.startTargetCamPosition.x + this.backendTargetCamPosition.x + this.frontEndTargetCamPosition.x + (this.contactTargetCamPosition.x) * (sV - 0.66) * 3,
        this.startTargetCamPosition.y + this.backendTargetCamPosition.y + this.frontEndTargetCamPosition.y + (this.contactTargetCamPosition.y) * (sV - 0.66) * 3,
        this.startTargetCamPosition.z + this.backendTargetCamPosition.z + this.frontEndTargetCamPosition.z + (this.contactTargetCamPosition.z) * (sV - 0.66) * 3
      )

      targetRot = new Euler(
        this.startTargetCamRotation.x + this.backendTargetCamRotation.x + this.frontEndTargetCamRotation.x + (this.contactTargetCamRotation.x) * (sV - 0.66) * 3,
        this.startTargetCamRotation.y + this.backendTargetCamRotation.y + this.frontEndTargetCamRotation.y + (this.contactTargetCamRotation.y) * (sV - 0.66) * 3,
        this.startTargetCamRotation.z + this.backendTargetCamRotation.z + this.frontEndTargetCamRotation.z + (this.contactTargetCamRotation.z) * (sV - 0.66) * 3
      )

    }

    // console.log(targetPos, targetRot, sV, scrollPercentage )
    this.camera.tweenCamera(targetPos, targetRot, 1000)
  }
  setting(showGui) {

    this.settings = {
      distortion: 0,
      speed: 1,
      paraX: 0,
      uSize: 5,
      color1: [225, 141, 223],
      color2: [0, 255, 0],
      color3: [255, 255, 255],
      color4: [45, 45, 45],

      colorSize: 1.6,
      colorSpacing: 0.662,
      colorRotation: -0.381592653589793,
      colorSpread: 4.52,
      displacement: 4.66,
      colorRotzoomation: 1,
      spacing: 1,
      seed: 3.9,

      percent: 0,
      interactive: false,
      noiseSize: 0.66,
      noiseIntensity: .00,

      progressX: 0,
      progressY: 0,


      transformPositionX: 0.9,
      transformPositionY: 0.01,

      distortionbg: 1,
      zoom: 0.72,
      spacing: 4.27,

    };



    if (showGui) {
      this.gui = new GUI();


      // settings for particles

      let linesFolder = this.gui.addFolder("BackendLines");
      linesFolder.add(this.settings, "percent", 0, 2, 1);




      let particleFolder = this.gui.addFolder("Partikel");
      particleFolder.add(this.settings, "distortion", 0, 1, 0.001);
      particleFolder.add(this.settings, "speed", 0, 10, 0.01);
      particleFolder.add(this.settings, "paraX", 0, 100, 1);
      particleFolder.add(this.settings, "uSize", 0, 10, 0.1);
      /*particleFolder.addColor(this.settings, "color1");
      particleFolder.addColor(this.settings, "color2");
      particleFolder.addColor(this.settings, "color3");*/


      let backgroundFolder = this.gui.addFolder("Hintergrund");

      backgroundFolder.addColor(this.settings, "color1");
      backgroundFolder.addColor(this.settings, "color2");
      backgroundFolder.addColor(this.settings, "color3");
      backgroundFolder.addColor(this.settings, "color4");
      backgroundFolder.add(this.settings, "transformPositionX", -10, 10, 0.01);
      backgroundFolder.add(this.settings, "transformPositionY", -10, 10, 0.01);

      backgroundFolder.add(this.settings, "interactive");

      backgroundFolder.add(this.settings, "colorSize", 0, 10, 0.01);
      backgroundFolder.add(this.settings, "colorSpacing");
      backgroundFolder.add(this.settings, "colorRotation");
      backgroundFolder.add(this.settings, "colorSpread");
      backgroundFolder.add(this.settings, "displacement");
      backgroundFolder.add(this.settings, "colorRotzoomation");
      backgroundFolder.add(this.settings, "spacing");
      backgroundFolder.add(this.settings, "zoom");

      backgroundFolder.add(this.settings, "seed");
      backgroundFolder.add(this.settings, "noiseSize");
      backgroundFolder.add(this.settings, "noiseIntensity");

      backgroundFolder.add(this.settings, "progressX", 0, 1, 0.01);
      backgroundFolder.add(this.settings, "progressY", 0, 1, 0.01);
      backgroundFolder.add(this.settings, "distortionbg", 0, 100, 0.1);

      linesFolder.open()

    }
  }

  reset() {

  }
  render() {
    this.renderer.render(this.scene, this.camera.getCam());
    this.renderer.render(this.scene2, this.camera2.getCam());
  }

  // Animation handlers
  start() {
    this.loop.start();
  }

  stop() {
    loop.stop();
  }
}

export { World };
