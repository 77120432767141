
import {
  ShaderMaterial,
  Vector2,
  Points,
  BufferAttribute,
  Color,
  AdditiveBlending,
  SphereGeometry,
  TextureLoader,
  Mesh,
  PlaneGeometry,
  MeshBasicMaterial,
} from "three";




// import gltf file


import vertex from "./GLS/vertexBackgroundStars.glsl";
import fragment from "./GLS/fragmentBackgroundStars.glsl";


class BackgroundStars {
  constructor(settings, scene, loop, scrolllevel) {
    this.scrollLevel = 0;

    // new sphere object
    let mesh = new PlaneGeometry(50, 15, 250, 100);




    let material = new ShaderMaterial({
      uniforms: {
        time: { value: 0. },

        uResolution: { value: new Vector2() },
        speed: { type: "f", value: 0 },
        //velocity: {type:"vec3", value:[1,2,3]},
        uSize: { type: "f", value: 1 },
        mouseX: { type: "f", value: 0 },
        mouseY: { type: "f", value: 0 },

        scroll: { type: "f", value: 0 },
        grain: { type: "f", value: 0 },



      color1: { value: new Color(0xFF0000) },
        color2: { value: new Color(0x00FF00) },
        color3: { value: new Color(0x0000FF) },
        color4: { value: new Color(0x00F00F) },

        colorSize: { type: "f", value: 1 },
        colorSpacing: { type: "f", value: 1 },
        colorRotation: { type: "f", value: 1 },
        colorSpread: { type: "f", value: 1 },

        displacement: { type: "f", value: 1 },
        colorRotzoomation: { type: "f", value: 1 },
        spacing: { type: "f", value: 1 },
        seed: { type: "f", value: 1 },


        viewportSize: { type: "vec2", value: [5000, 2] },
        colorOffset: { type: "vec2", value: [-0.7741174697875977, -0.20644775390624992] },
        transformPosition: { type: "vec2", value: [0.2816110610961914, -0.43914794921875] },

        noiseSize: { type: "f", value: 1 },
        noiseIntensity: { type: "f", value: 1 },
        zoom: { type: "f", value: 1 },
        spacing: { type: "f", value: 1 },


        progressX: { type: "f", value: 1 },
        progressY: { type: "f", value: 0 },
        interactive: { type: "b", value: false },
        distortion: { type: "f", value: 0 },



      },
      // add shaders
      vertexShader: vertex,
      fragmentShader: fragment,

      // add other material properties
      depthTest: false,
      transparent: true,
      depthWrite: false,
      // blending: AdditiveBlending,


    });



    let random = new Float32Array(mesh.attributes.position.array.length / 3);
    let colorRandom = new Float32Array(mesh.attributes.position.array.length / 3);

    for (let i = 0; i < random.length; i++) {
      random[i] = Math.random();
      colorRandom[i] = Math.random();
    }

    mesh.setAttribute("aRandom", new BufferAttribute(random, 1));
    mesh.setAttribute("aColorRandom", new BufferAttribute(colorRandom, 1));


    // apply material to mesh

    // scale mesh

    // mesh = new BoxGeometry(10,10, 10, 100, 100, 100)
    //material = new MeshBasicMaterial({ color: 0x00ff00 });
    let pixel = new Points(mesh, material);

    // roate ny 90 degrees
    //pixel.rotateX(-1.75)



    pixel.tick = (delta) => {
      material.uniforms.time.value += delta;
      // update mouse postion in shader


      // console.log(mouse)

      material.uniforms.uResolution.value.x = window.innerWidth;
      material.uniforms.uResolution.value.y = window.innerHeight;

      material.uniforms.scroll.value = settings.progressScroll;
      material.uniforms.grain.value = settings.grain;

      material.uniforms.color1.value = settings.color1;
      material.uniforms.color2.value = settings.color2;
      material.uniforms.color3.value = settings.color3;
      material.uniforms.color4.value = settings.color4;
      material.uniforms.colorSize.value = settings.colorSize;
      material.uniforms.colorSpacing.value = settings.colorSpacing;
      material.uniforms.colorRotation.value = settings.colorRotation;
      material.uniforms.colorSpread.value = settings.colorSpread;
      material.uniforms.displacement.value = settings.displacement;
      material.uniforms.colorRotzoomation.value = settings.colorRotzoomation;
      material.uniforms.spacing.value = settings.spacing;
      material.uniforms.seed.value = settings.seed;
      material.uniforms.noiseSize.value = settings.noiseSize;
      material.uniforms.noiseIntensity.value = settings.noiseIntensity;
      material.uniforms.zoom.value = settings.zoom;
      material.uniforms.interactive.value = settings.interactive;

      material.uniforms.viewportSize.value = [1600, 700];



      material.uniforms.transformPosition.value.x = settings.transformPositionX;
      material.uniforms.transformPosition.value.y = settings.transformPositionY;



      material.uniforms.progressY.value = this.scrollLevel;

      material.uniforms.distortion.value = settings.distortionbg;


    };

    loop.updatables.push(pixel);

    //  pixel.scale.set( 0.01, 0.01, 0.01 );
    scene.add(pixel)
    // scene.add(pixel);

  }

  setScrollLevel(scrollLevel) {
    this.scrollLevel = scrollLevel;
  }

}

export { BackgroundStars };





