const setSize = (container, camera, camera2, renderer) => {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();


  camera2.aspect = window.innerWidth / window.innerHeight;
  camera2.updateProjectionMatrix();


  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.setPixelRatio(window.devicePixelRatio);
};

class Resizer {
  constructor(container, camera, camera2, renderer) {
    // set initial size on load
    setSize(container, camera, camera2, renderer);
    setSize(container, camera, camera2, renderer);

    window.addEventListener("resize", () => {
      // set the size again if a resize occurs
      setSize(container, camera, camera2, renderer);
      // perform any custom actions
      this.onResize();
    });
  }

  onResize() {}
}

export { Resizer };
