import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'


import  I18nInit  from './translations.js';


console.log(I18nInit);


let i18n = I18nInit();


createApp(App)
  .use(vuetify)
  .use(i18n)
  .mount('#app')
