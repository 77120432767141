
import {SmartPhone} from "./Smartphone.js"
import {ShowReel} from "./ShowReel.js";







class Frontend {
    
    constructor(settings, scene, loop, particleStart) {
        this.smartPhone = new SmartPhone(settings, scene, loop, particleStart);
        this.showReel =  new ShowReel(settings, scene, loop, particleStart);
    }

    setIndex(index) {
        this.showReel.index = index;
    }

    updateAlpha(alpha) {
        this.showReel.alpha = alpha;
        this.smartPhone.alpha = alpha;
    }

    setScrollLevel(level) {
        this.smartPhone.setScrollLevel(level);
    }    
}
export  { Frontend };
