<template>
  <v-btn @click="$emit('reset')" :class="isMobile ? 'backButtonMobile' : 'backButton'">Zurück</v-btn>

  <div :class="isMobile ? 'legalMobile' : 'legal'">
    <h1 class="title">Datenschutzerklärung</h1>

    <h2 class="distance">Allgemeiner Hinweis und Pflichtinformationen</h2>

    <h2 class="distance">Benennung der verantwortlichen Stelle</h2>

    <p class="text">
      Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
      ist:<br /><br />
      Robin Haensse <br />
      <p class="text">Kaiser-Joseph-Str. 254</p>
      <p class="text">79098 Freiburg</p> <br /><br />
      Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen
      über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
      (z.B. Namen, Kontaktdaten o. Ä.).
    </p>

    <h2 class="distance">Widerruf Ihrer Einwilligung zur Datenverarbeitung</h2>
    <p class="text">
      Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der
      Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten
      Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose
      Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
      Datenverarbeitung bleibt vom Widerruf unberührt.
    </p>

    <h2 class="distance">
      Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde
    </h2>
    <p class="text">
      Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen
      Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
      Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist
      der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz
      unseres Unternehmens befindet. Der folgende Link stellt eine Liste der
      Datenschutzbeauftragten sowie deren Kontaktdaten bereit:
      https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
    </p>

    <h2 class="distance">Recht auf Datenübertragbarkeit</h2>
    <p class="text">
      Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung
      oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder
      an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem
      maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an
      einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es
      technisch machbar ist.
    </p>
    <h2 class="distance">
      Recht auf Auskunft, Berichtigung, Sperrung, Löschung
    </h2>
    <p class="text">
      Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das
      Recht auf unentgeltliche Auskunft über Ihre gespeicherten
      personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck
      der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder
      Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema
      personenbezogene Daten können Sie sich jederzeit über die im Impressum
      aufgeführten Kontaktmöglichkeiten an uns wenden. SSL- bzw.
      TLS-Verschlüsselung Aus Sicherheitsgründen und zum Schutz der Übertragung
      vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt
      unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die
      Sie über diese Website übermitteln, für Dritte nicht mitlesbar. Sie
      erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile
      Ihres Browsers und am Schloss-Symbol in der Browserzeile.
    </p>
    <h2 class="distance">Kontaktformular</h2>
    <p class="text">
      Per Kontaktformular übermittelte Daten werden einschließlich Ihrer
      Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder um für
      Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten findet ohne
      Ihre Einwilligung nicht statt. Die Verarbeitung der in das Kontaktformular
      eingegebenen Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung
      (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten
      Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose
      Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
      Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. Über das
      Kontaktformular übermittelte Daten verbleiben bei uns, bis Sie uns zur
      Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder
      keine Notwendigkeit der Datenspeicherung mehr besteht. Zwingende
      gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen - bleiben
      unberührt.
    </p>
    <p class="text">Quelle: Datenschutz-Konfigurator von mein-datenschutzbeauftragter.de</p>
  </div>
</template>

<script>
export default {
  name: "Gpdr",
  props: {
    isMobile: Boolean,
  },
  data() {
    return {};
  },

  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.backButton {
  position: fixed;
  top: 40%;
  left: 12.5%;

  border: 3px solid #EEE;
  color: #EEE;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.7vw;
  cursor: pointer;
}

.backButtonMobile {
  position: fixed;
  top: 40%;
  left: 2.5%;
  width: 1vw;
  border: 3px solid #eee;
  color: #eee;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 2vw;
  cursor: pointer;
}


.legal {
  position: relative;
  left: 25%;
  top:5%;
  color: #EEE;
  width: 50%;
  padding: 50px;
  background-color: #006D00B4;
}


.legalMobile {
  position: fixed;
  left: 23%;
  top:10%;
  color: #EEE;
  width: 72%;
  padding: 2vw;
  background-color: #006D00B4;
}

.distance {
  margin-top: 3vh;
  margin-bottom: 10px;
  font-size: 2vh;
}

.title {
  margin-top: 3vh;
  font-size: 3vh;
}

.text {
  font-size: 1.5vh;

}

</style>
