<template>
  <div
    class="hovercontainer"
    @mouseover="forceShow = true"
    @mouseleave="forceShow = false"
  >
    <Transition>
      <div v-if="semeaphores.scrolled || forceShow">
        <p :class="isMobile ? 'startMobile' : 'start'" @click="$emit('start')">{{ $t("menu.start")}}</p>
        <button   v-if="!(isMobile && semeaphores.skillsEarly)" :class="isMobile ? 'buttonMobile' : 'button'" @click="$emit('backend')">{{ $t("menu.skills")}}</button>
        <button :class="isMobile ? 'buttonMobile' : 'button'" :style="[!isMobile ? 'top: 75%' : 'top: 85%']" @click="$emit('frontend')">
          {{ $t("menu.projects")}}
        </button>
        <button :class="isMobile ? 'buttonMobile' : 'button'" :style="[!isMobile ? 'top: 85%' : 'top: 95%']" @click="$emit('contact')">
          {{ $t("menu.contact")}}
        </button>
      </div>
    </Transition>

    <div
      v-for="(line, index) in navLine"
      :class="index % 5 == 0 ? 'line-thick' : 'line'"
      :style="'top:' + (55 + (isMobile * 10) + index * 2) + '%;'"
      :key="index"
    ></div>
    <Transition>
      <div
        v-if="semeaphores.scrolled || forceShow"
        :class="isMobile ? 'triangleMobile' : 'triangle'"
        :style="
          'top:' +
          (55 + (isMobile * 10) + Math.round((navLine.length - 2) * percentage) * 2) +
          '%'
        "
      ></div>
    </Transition>

    <Transition>
      <div v-if="!semeaphores.scrolled && !forceShow" :class="isMobile ? 'triangle2Mobile' : 'triangle2'"></div>
    </Transition>
    
  </div>
</template>

<script>
export default {
  name: "Menu",
  props: {
    semeaphores: Object,
    percentage: Number,
    isMobile: Boolean,
  },
  data() {
    return {
      navLine: new Array(17).fill(0),
      forceShow: false,
    };
  },

  mounted() {},

  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hovercontainer {
  width: 15%;
  height: 50%;

  left: 3%;
  top: 50%;
  position: fixed;
  z-index: 100;
  /* background: coral;    */
}
.line {
  position: fixed;
  offset-anchor: 50% 50%;
  width: 10px;
  height: 3px;
  left: 6%;
  top: 55%;
  background: rgba(255, 255, 255, 0.226);
  transform: translate(-50%, -50%);
}

.line-thick {
  position: fixed;
  offset-anchor: 50% 50%;

  width: 20px;
  height: 3px;
  left: 6%;
  top: 55%;
  background: #EEE;
  transform: translate(-50%, -50%);
}

.start {
  border: none;
  color: #EEE;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1vw;
  font-family: "Audiowide";

  cursor: pointer;
  left: 6%;
  top: 50%;
  width: 10%;
  position: fixed;
  transform: translate(-50%, 0%);
}
.start:hover {
  border: none;
  color: "#29c929";
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1vw;
  cursor: pointer;
  left: 6%;
  top: 50%;
  width: 10%;
  position: fixed;
  transform: translate(-50%, 0%);
}



.startMobile {
  border: none;
  color: #EEE;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 3vw;
  cursor: pointer;
  left: 6%;
  top: 60%;
  width: 10%;
  position: fixed;
  transform: translate(-50%, 0%);
}





.triangle {
  width: 0;
  height: 0;
  left: 4%;
  top: 55%;
  position: fixed;
  transform: translate(-50%, -50%);
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #29c929;
}
.triangleMobile {
  width: 0;
  height: 0;

  left: 2%;
  top: 65%;
  position: fixed;
  transform: translate(-50%, -50%);
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #29c929;
}

.triangle2 {
  width: 10px;
  height: 0;
  offset-anchor: 50% 50%;

  left: 6%;
  top: 52%;
  position: fixed;
  transform: translate(-50%, 0) rotate(90deg);
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 8px solid #29c929;
}

.triangle2Mobile {
  width: 10px;
  height: 0;
  offset-anchor: 50% 50%;

  left: 6%;
  top: 62%;
  position: fixed;
  transform: translate(-50%, 0) rotate(90deg);
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 8px solid #29c929
}




.button {
  transition: all 0.5s ease;
  border: 3px solid #EEE;
  background-color: transparent;
  color: #EEE;
  padding: 1.2vh 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: "Audiowide";
  font-size: 1vw;
  cursor: pointer;
  left: 10%;
  top: 65%;
  width: 10%;
  position: fixed;
  transform: translate(-50%, -50%);
}

.buttonMobile {
  transition: all 0.5s ease;
  color: #EEE;
  background-color: transparent;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 3vw;
  cursor: pointer;
  left: 10%;
  top: 75%;
  width: 10%;
  position: fixed;
  transform: translate(-50%, -50%);
}



.button:hover {
  color: #001f3f;
  background-color: #EEE;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.45s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
