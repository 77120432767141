<template>
  <div class="timer">
    <p>{{ name }}:</p>
    <v-progress-circular v-if="loading" color="#a6e59b" indeterminate></v-progress-circular>
    <p v-else class="ms">{{ timer }} ms</p>
  </div>
</template>

<script>
export default {
  name: "ApiTimer",
  components: {},
  props: {
    name: String,
    timer: Number,
    loading: Boolean,
  },
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped>

.timer {
  color: #eee;
  position: relative;
  display: flex;
}
</style>
