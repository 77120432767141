
import {
  ShaderMaterial,
  Vector2,
  Points,
  BufferAttribute,
  Color,
  AdditiveBlending,
  SphereGeometry,
  TextureLoader,
  Mesh,
  Raycaster,
  IcosahedronGeometry,
  CubicBezierCurve3,
  Vector3,
  BufferGeometry,
  LineBasicMaterial,
  Line,
} from "three";




// import gltf file


import vertex from "./GLS/vertexLines.glsl";
import fragment from "./GLS/fragmentLines.glsl";



class Lines {
  constructor(settings, scene, loop, particleStart, camera) {

  
  
    this.step = [0, 0, 0, 0];
  
    let materialAM = new ShaderMaterial({
      uniforms: {
        time: { value: 0. },
        resolution: { value: new Vector2() },
        //velocity: {type:"vec3", value:[1,2,3]},
        percent: { type: "f", value: 0 },
        away: { type: "f", value: false },
      },
      // add shaders
      vertexShader: vertex,
      fragmentShader: fragment,
  
      // add other material properties
      depthTest: true,
      transparent: false,
      depthWrite: true,
      wireframe: false,
      // blending: AdditiveBlending,
    });
    let materialSH = new ShaderMaterial({
      uniforms: {
        time: { value: 0. },
        resolution: { value: new Vector2() },
        //velocity: {type:"vec3", value:[1,2,3]},
        percent: { type: "f", value: 0 },
        away: { type: "f", value: false },
      },
      // add shaders
      vertexShader: vertex,
      fragmentShader: fragment,
  
      // add other material properties
      depthTest: true,
      transparent: false,
      depthWrite: true,
      wireframe: false,
      // blending: AdditiveBlending,
    });

    let materialFR = new ShaderMaterial({
      uniforms: {
        time: { value: 0. },
        resolution: { value: new Vector2() },
        //velocity: {type:"vec3", value:[1,2,3]},
        percent: { type: "f", value: 0 },
        away: { type: "f", value: false },
      },
      // add shaders
      vertexShader: vertex,
      fragmentShader: fragment,
  
      // add other material properties
      depthTest: true,
      transparent: false,
      depthWrite: true,
      wireframe: false,
      // blending: AdditiveBlending,
    });
    let materialSP = new ShaderMaterial({
      uniforms: {
        time: { value: 0. },
        resolution: { value: new Vector2() },
        //velocity: {type:"vec3", value:[1,2,3]},
        percent: { type: "f", value: 0 },
        away: { type: "f", value: false },
      },
      // add shaders
      vertexShader: vertex,
      fragmentShader: fragment,
  
      // add other material properties
      depthTest: true,
      transparent: false,
      depthWrite: true,
      wireframe: false,
      // blending: AdditiveBlending,
    });



    
    // line to america
    const curveAM = new CubicBezierCurve3(
      new Vector3( -45, 31.5, 0 ),
      new Vector3( -50, 31.5, -10),
      new Vector3( -50, 31.5, -60 ),
      new Vector3( 35, 31.5, -50)
    );


    // line to london
    const curveSH = new CubicBezierCurve3(
      new Vector3( -45, 31.5, 0 ),
      new Vector3( -47, 34, 0 ),
      new Vector3( -47, 34, 0 ),
      new Vector3( -42, 35, -8)
    );

    
    // line to frankfurt
    const curveFR = new CubicBezierCurve3(
      new Vector3( -45, 31.5, 0 ),
      new Vector3( -46, 33, 0.1 ),
      new Vector3( -46, 33, 0.3 ),
      new Vector3( -44, 33, 0.5)
    );


    // line to spain
    const curveSP = new CubicBezierCurve3(
      new Vector3( -45, 31.5, 0 ),
      new Vector3( -51, 33, 25),
      new Vector3( -51, 14, 40 ),
      new Vector3( -36, 13, 42)
    );



    const pointsFR = curveFR.getPoints( 2000 );
    const geometryFR = new BufferGeometry().setFromPoints( pointsFR );
    const pointsSH = curveSH.getPoints( 2000 );
    const geometrySH = new BufferGeometry().setFromPoints( pointsSH );
    const pointsAM = curveAM.getPoints( 2000 );
    const geometryAM = new BufferGeometry().setFromPoints( pointsAM );
    const pointsSP = curveSP.getPoints( 2000 );
    const geometrySP = new BufferGeometry().setFromPoints( pointsSP );



    let index = new Float32Array(geometryFR.attributes.position.array.length / 3)
    for (let i = 0; i < index.length; i++) {        
      index[i] = i;
    }







    geometryFR.setAttribute("aIndex", new BufferAttribute(index, 1));
    geometryFR.rotateX(Math.PI / 2);

    geometrySH.setAttribute("aIndex", new BufferAttribute(index, 1));
    geometrySH.rotateX(Math.PI / 2);

    geometryAM.setAttribute("aIndex", new BufferAttribute(index, 1));
    geometryAM.rotateX(Math.PI / 2);

    geometrySP.setAttribute("aIndex", new BufferAttribute(index, 1));
    geometrySP.rotateX(Math.PI / 2);












    this.curveObjectFR = new Line( geometryFR, materialFR );
    this.curveObjectFR.tick = (delta) => {
      materialFR.uniforms.time.value += delta;
      materialFR.uniforms.away.value = this.away;
      
      if(this.step[0] == 0 ) {
        this.percent = 0;
        materialFR.uniforms.percent.value = this.percent;
      } else if(this.step[0] == 1) {
        materialFR.uniforms.percent.value = Math.min(0.5, materialFR.uniforms.percent.value + delta/2); 
      } else if( this.step[0] == 2) {
        materialFR.uniforms.percent.value = Math.min(1, materialFR.uniforms.percent.value + delta/2); 
      }


    };


    this.curveObjectSH = new Line( geometrySH, materialSH );
    this.curveObjectSH.tick = (delta) => {
      materialSH.uniforms.time.value += delta;
      materialSH.uniforms.away.value = this.away;
      
      if(this.step[1] == 0 ) {
        this.percent = 0;
        materialSH.uniforms.percent.value = this.percent;    
        } else if(this.step[1] == 1) {
        materialSH.uniforms.percent.value = Math.min(0.5, materialSH.uniforms.percent.value + delta/2); 
      } else if( this.step[1] == 2) {
        materialSH.uniforms.percent.value = Math.min(1, materialSH.uniforms.percent.value + delta/2); 
      }

    };

    this.curveObjectAM = new Line( geometryAM, materialAM );
    this.curveObjectAM.tick = (delta) => {
      materialAM.uniforms.time.value += delta;
      materialAM.uniforms.away.value = this.away;
  
      if(this.step[2] == 0 ) {
        this.percent = 0;
        materialAM.uniforms.percent.value = this.percent;  
          } else if(this.step[2] == 1) {
        materialAM.uniforms.percent.value = Math.min(0.5, materialAM.uniforms.percent.value + delta/2); 
      } else if( this.step[2] == 2) {
        materialAM.uniforms.percent.value = Math.min(1, materialAM.uniforms.percent.value + delta/2); 
      }

    };


    this.curveObjectSP = new Line( geometrySP, materialSP );
    this.curveObjectSP.tick = (delta) => {
      materialSP.uniforms.time.value += delta;
      materialSP.uniforms.away.value = this.away;
      
      if(this.step[3] == 0 ) {
        this.percent = 0;
        materialSP.uniforms.percent.value = this.percent;
            } else if(this.step[3] == 1) {
        materialSP.uniforms.percent.value = Math.min(0.5, materialSP.uniforms.percent.value + delta/2); 
      } else if( this.step[3] == 2) {
        materialSP.uniforms.percent.value = Math.min(1, materialSP.uniforms.percent.value + delta/2); 
      }


    };
      this.curveObjectSH.rotateX(- Math.PI / 2);
      this.curveObjectFR.rotateX(- Math.PI / 2);
      this.curveObjectAM.rotateX(- Math.PI / 2);
      this.curveObjectSP.rotateX(- Math.PI / 2);

      loop.updatables.push(this.curveObjectSH);
      loop.updatables.push(this.curveObjectFR);
      loop.updatables.push(this.curveObjectAM);
      loop.updatables.push(this.curveObjectSP);

      scene.add(this.curveObjectFR)
      scene.add(this.curveObjectSH)
      scene.add(this.curveObjectAM)
      scene.add(this.curveObjectSP)



  
    //  pixel.scale.set( 0.01, 0.01, 0.01 );
  }
  // new sphere object
  
  // scene.add(pixel);

  startRequest() {
    this.step[0] = 1;
    this.step[1] = 1;
    this.step[2] = 1;
    this.step[3] = 1;

  }

  clearRequest() {
    this.step[0] = 0;
    this.step[1] = 0;
    this.step[2] = 0;
    this.step[3] = 0;
  }

  finishRequest(ix) {
    this.step[ix] = 2;

  }



  updatePostion(away) {
    this.away = away;
  }


}


export {Lines};








