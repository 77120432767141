<template>
  <div>
    <div v-if="semeaphores.workEarly" class="header">{{ $t("work.title") }}</div>

    <Transition name="fade" appear>
      <div v-if="semeaphores.work">
        <div class="containerHeader container">
          <p class="title">{{ content[index].title }}</p>
          <div class="contracter">
            <p>{{ content[index].contracter }}, {{ content[index].year }}</p>

            <Transition name="arrow" appear>
              <div v-if="semeaphores.work" :class="index == 0 ? 'topline' : 'toplineActive'"></div>
            </Transition>
            <Transition name="arrow" appear>
              <div v-if="semeaphores.work" class="topline2"></div>
            </Transition>

            <div style="display: flex; margin-bottom: 4px">
              <a v-if="content[index].pageLink.length > 1" :href="content[index].pageLink" class="link" style="margin-right: 3px" target="_blank"
                >Visit Page<v-icon label="visit">mdi-open-in-new</v-icon></a
              >
              <a v-if="content[index].github.length > 1" :href="content[index].github" class="link" target="_blank">Code<v-icon>mdi-github</v-icon></a>
              <a v-for="d in content[index].moreLinks" :key="d" :href="d[0]" class="link" target="_blank">
                <p>{{ d[1] }}</p>
                <v-icon label="visit">mdi-open-in-new</v-icon>
              </a>
            </div>
          </div>
        </div>

        <div class="skillsContainer container">
          <Transition name="arrow" appear>
            <div v-if="semeaphores.work" :class="index == 0 ? 'botline' : 'botlineActive'"></div>
          </Transition>
          <Transition name="arrow" appear>
            <div v-if="semeaphores.work" class="botline2"></div>
          </Transition>

          <div style="display: flex">
            <div class="skillCol">
              <p class="skill-header">{{ $t("work.toolsUsed") }}</p>
              <div class="line"></div>

              <p>{{ content[index].skill }}</p>
            </div>
            <div class="skillCol">
              <p class="skill-header">{{ $t("work.whatIdid") }}</p>
              <div class="line"></div>
              <p>{{ content[index].idid }}</p>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <Transition name="fade">
      <v-icon v-if="semeaphores.work" class="buttonFrontendLeft" size="10vw" @click="$emit('workLeft')">mdi-chevron-left</v-icon>
    </Transition>

    <Transition name="fade">
      <v-icon v-if="semeaphores.work" class="buttonFrontendRight" @click="$emit('workRight')" size="10vw">mdi-chevron-right</v-icon>
    </Transition>
  </div>
</template>

<script>
export default {
  name: "Work",
  components: {},
  props: { index: Number, semeaphores: Object },

  data() {
    return {
      content: [
        {
          title: "Haensse.dev Portfolio",
          year: "2023",
          contracter: this.$t("work.projects.portfolio.contracter"),
          contracter_link: "",
          github: "https://github.com/StandardWobin/landing",
          pageLink: "haensse.dev",
          moreLinks: [],
          skill: "VueJs, TheeJs, Vuefity, Vitest, Pupeteer, NodeJs, Express, i18n, Apache2, Webpack",
          idid: this.$t("work.projects.portfolio.idid"),
        },
        {
          title: this.$t("work.projects.cloud.title"),
          year: "2021-2022",
          contracter: "AGJ Fachverband",
          contracter_link: "https://www.agj-freiburg.de/",
          github: "https://github.com/AGJ-Freiburg/DO-cloud",
          pageLink: "",
          moreLinks: [
            ["https://background.tagesspiegel.de/smart-city/eine-cloud-fuer-wohnungslose-menschen", this.$t("work.projects.cloud.morePress")],
            ["https://www.caritas.de/neue-caritas/heftarchiv/jahrgang2022/artikel/den-perso-immer-parat", this.$t("work.projects.cloud.morePress2")],
          ],
          skill: "VueJs, Vuetify, Nextcloud API (Backend), Apache2, Webpack",
          idid: this.$t("work.projects.cloud.idid"),

        },
        {
          title: this.$t("work.projects.megapart.title"),
          year: "2022",
          contracter: "AGJ Fachverband",
          contracter_link: "https://www.agj-freiburg.de/",
          github: "https://github.com/AGJ-Freiburg/DO-survey-frontend",
          pageLink: "",
          moreLinks: [["https://github.com/AGJ-Freiburg/DO-survey-backend", "Backend"]],
          skill: "VueJs, Vuetify, Javascript, NodeJs + ExpressJs, MySql, Apache2, Python",
          idid: this.$t("work.projects.megapart.idid"),

        },
        {
          title: "uni.theater",
          year: "2019",
          contracter: "FIST Freiburg",
          contracter_link: "http://uni.theater",
          github: "",
          pageLink: "http://uni.theater",
          moreLinks: [],
          skill: "Wordpress, Enfold",
          idid: this.$t("work.projects.unitheater.idid"),
        },
        {
          title: "Mundwerk Theater Kollektiv",
          year: "2018",
          contracter: "FIST Freiburg",
          contracter_link: "https://mundwerk-theaterkollektiv.de/",
          github: "",
          pageLink: "https://mundwerk-theaterkollektiv.de/",
          moreLinks: [],
          skill: "Wordpress, Enfold",
          idid: this.$t("work.projects.mundwerk.idid"),
        },
        {
          title: "Hechtzeit",
          year: "2021",
          contracter: "FIST Freiburg / ArtiK Freiburg",
          contracter_link: "http://uni.theater",
          github: "",
          pageLink: "",
          moreLinks: [
            ["https://uni.theater/wedekindadaption/", this.$t("work.projects.hechtzeit.moreInfo")],
            [
              "https://www.youtube.com/watch?time_continue=4&v=9lKgdQl4y4g&embeds_euri=https%3A%2F%2Funi.theater%2F&feature=emb_logo",
              this.$t("work.projects.hechtzeit.moreAction"),
            ],
            [
              "https://nachtkritik.de/index.php?option=com_content&view=article&id=19815:fruehlings-erwachsen-eine-adaption-nach-ihrer-wahl-freiburger-interessenverband-fuer-studentisches-theater-robin-haensse-zeigt-interaktives-netztheaterstueck-nach-frank-wedekind&catid=1835&Itemid=60",
              this.$t("work.projects.hechtzeit.morePress"),
            ],
          ],
          skill: "VueJS, Vuetify, NodeJs, ExpressJS, NGINX, mySQL, Socket.IO, Vitest, Puppeteer, C, C#",
          idid: this.$t("work.projects.hechtzeit.idid"),
        },
        {
          title: "Black Forest Tales",
          year: "2022",
          contracter: "ArTik Freiburg",
          contracter_link: "https://www.artik-freiburg.de/",
          github: "",
          pageLink: "https://blackforesttales.com",
          moreLinks: [],
          skill: "Wordpress, Divi",
          idid: this.$t("work.projects.blackforesttales.idid"),
        },
        {
          title: this.$t("work.projects.hiwiAIS.title"),
          year: "2016",
          contracter: this.$t("work.projects.hiwiAIS.contracter"),
          contracter_link: "http://ais.informatik.uni-freiburg.de/",
          github: "",
          pageLink: "",
          moreLinks: [],
          skill: "Matlab, Raytracing, CUDA",
          idid: this.$t("work.projects.hiwiAIS.idid"),
        },
        {
          title: this.$t("work.projects.hiwiSimu.title"),
          year: "2017",
          contracter: this.$t("work.projects.hiwiSimu.contracter"),
          contracter_link: "https://www.imtek.uni-freiburg.de/professuren/simulation/simulation",
          github: "",
          pageLink: "",
          moreLinks: [],
          skill: "Python, Multiprocessing, Numpy, Matplotlib",
          idid: this.$t("work.projects.hiwiSimu.idid"),
        },
        {
          title: this.$t("work.projects.hiwiCogmod.title"),
          year: "2020",
          contracter: this.$t("work.projects.hiwiCogmod.contracter"),
          contracter_link: "uni.theater",
          github: "",
          pageLink: "",
          moreLinks: [],
          skill: "Lisp, ACT-R, Python, Academic research",
          idid: this.$t("work.projects.hiwiCogmod.idid"),
        },
      ],
    };
  },

  methods: {},
};
</script>

<style scoped>
.skillCol {
  width: 50%;
  padding: 0.2em 0.2em;
}

.line-enter-active,
.line-leave-active {
  transition: opacity 1s ease;
  transition: width 1s ease;
}

.line-enter-from,
.line-leave-to {
  opacity: 0;
  width: 0%;
}

.container {
  background: #006d006b;
  border-radius: 25px;
  color: white;
  padding: 2em 2em;
}

.didcontainer {
  position: fixed;
  top: 70%;
  left: 20%;
  z-index: 1000;
}

.containerHeader {
  position: fixed;
  top: 12%;
  left: 8%;
  width: 30%;
  font-size: 1vw;
}

.skillsContainer {
  position: fixed;
  width: 25%;
  top: 55vh;
  left: 70%;
  padding: 2em 2em;
  font-size: 1vw;
}

.link {
  padding: 1em 0em;
  padding-right: 1em;
  display: flex;
  text-decoration: none;
}

.skill-header {
  font-size: 1.5em;
  margin-bottom: 1em;
}

.header {
  position: fixed;
  left: 62%;
  top: 5%;
  color: #eee;
  font-family: "Audiowide", sans-serif;
  font-size: 5.1vw;
  color: #fafafa;
  transform: perspective(400px) rotateY(-3deg) rotateZ(-2deg);
  z-index: 1000;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.didelement {
  color: #eee;
}

.title {
  color: #eee;
  font-size: 2.1vw;
  font-family: "Audiowide";
}

.contracter {
  position: relative;
  color: #eee;
  font-size: 0.8vw;
}

.topline {
  width: 50%;
  left: 100%;
  height: 3px;
  background: #29c929;
  position: relative;
  border-radius: 0.5rem;

  transform: rotate(50deg);
  transform-origin: 0 0;
}

.toplineActive {
  width: 25%;
  left: 100%;
  height: 3px;
  background: #29c929;
  position: relative;
  border-radius: 0.5rem;

  transform: rotate(25deg);
  transform-origin: 0 0;
}

.topline2 {
  width: 100%;
  left: 100%;
  background: #29c929;
  position: relative;
  border-radius: 0.5rem;
  height: 3px;
  position: relative;
  transform: rotate(180deg) translate(0%, 0%);
  transform-origin: 0 0;
}

.botline {
  width: 78%;
  left: 0%;
  height: 3px;
  background: #29c929;
  position: relative;
  border-radius: 0.5rem;

  transform: rotate(195deg) translate(-1%, -200%);
  transform-origin: 0 0;
}

.botlineActive {
  width: 68%;
  left: 0%;
  height: 3px;
  background: #29c929;
  position: relative;
  border-radius: 0.5rem;

  transform: rotate(195deg) translate(-1%, -200%);
  transform-origin: 0 0;
}

.botline2 {
  width: 100%;
  background: #29c929;
  position: relative;
  border-radius: 0.5rem;
  height: 3px;
}

.arrow-enter-active,
.arrow-leave-active {
  transition: opacity 1s ease;
  transition: width 1s ease;
}

.arrow-enter-from,
.arrow-leave-to {
  opacity: 0;
  width: 0%;
}

.frontendInfos {
  width: 10%;
  height: 5%;
  position: fixed;
  top: 75%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@keyframes smallBig {
  0% {
    scale: 1;
  }
  50% {
    scale: 1.3;
  }
  100% {
    scale: 1;
  }
}

.buttonFrontendLeft {
  transition: all 0.5s ease;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.623);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  left: 21%;
  top: 37%;
  position: fixed;
  color: #7dda71;
  animation: smallBig 3s;
  animation-iteration-count: infinite;
  z-index: 100;
}

.buttonFrontendRight {
  transition: all 0.5s ease;
  background-color: transparent;
  background-color: Transparent;
  color: rgba(255, 255, 255, 0.623);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  left: 70%;
  top: 37%;
  color: #7dda71;

  position: fixed;
  z-index: 1000;
  animation: smallBig 3s;
  animation-iteration-count: infinite;
}

.buttonFrontendRight:hover {
  color: rgba(255, 255, 255, 1);
}

.buttonFrontendLeft:hover {
  color: rgba(255, 255, 255, 1);
}
</style>
