<template>
  <transition name="content">
    <div v-if="semeaphores.contact" :class="isMobile ? 'formMobile' : 'form'">
      <p v-if="isMobile" class="lazyMobileTitle">Haensse.dev</p>
      <p v-if="isMobile" class="lazyMobileTitle2">Robin Haensse | {{$t("contact.fullStack")}}</p>
      <p v-if="isMobile" class="lazyMobileTitle3">>{{$t("contact.writeMe")}}</p>

      <v-form ref="form"  v-model="valid" dark lazy-validation>
        <p v-if="!isMobile" class="textme">{{$t("contact.title")}}</p>

        <v-text-field v-model="name" :rules="nameRules" :label="$t('contact.name')"  color="#EEE" bg-color="#FFFFFF5B" required></v-text-field>
        <v-text-field v-model="email" :rules="emailRules" :label="$t('contact.email')"  color="#EEE" bg-color="#FFFFFF5B" required></v-text-field>
        <v-textarea v-model="textarea"  density="compact" dark :rules="textRules" :label="$t('contact.message')" color="#EEE" bg-color="#FFFFFF5B" required></v-textarea>

        <div style="display: flex">
          <v-checkbox v-model="checkbox" :rules="[(v) => !!v || $t('contact.gpdrhint')]" required>
            <template v-slot:label>
              <p v-html="$t('contact.gpdrText', ['<a href=\'#gpdr\'  target=\'_blank\'>' + $t('contact.gpdr') + '</a>'])"></p>
            </template>
          </v-checkbox>
        </div>

        <div :class="isMobile ? 'sendButtonMobile' : 'sendButton'">
          <v-btn color="#E18DDF" @click="validate" :disabled="loading || sent">
            {{$t("contact.send")}} <v-progress-circular v-if="loading" indeterminate color="#EEE"></v-progress-circular
          ></v-btn>

          <p v-if="sent">{{$t("contact.emailSentSuccess")}}</p>
          <p v-if="error" style="padding-left: 3em">{{$t("contact.emailSentError")}}</p>
        </div>
      </v-form>

      <div v-if="!isMobile" class="line"></div>

      <div class="otherContacts">
        <a href="https://wa.me/01759959692" target="_blank" class="subLinks" style="text-decoration: none; display: flex">
          <v-icon color="#a6e59b"> mdi-whatsapp </v-icon>
          <p style="padding-left: 1em">+0175 9959692</p>
        </a>

        <a href="mailto:hello@haensse.de" target="_blank" class="subLinks" style="text-decoration: none; display: flex">
          <v-icon color="#edbceb"> mdi-email</v-icon>
          <p style="padding-left: 1em">hello@haensse.dev</p>
        </a>

        <a href="https://www.linkedin.com/in/haensse/" target="_blank" class="subLinks" style="text-decoration: none; display: flex">
          <v-icon color="#0077b5"> mdi-linkedin</v-icon>
          <p style="padding-left: 1em">in/haensse</p>
        </a>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ContactForm",
  components: {},
  props: {
    semeaphores: Object,
    isMobile: Boolean,
  },
  data() {
    return {
      loading: false,
      valid: false,
      sent: false,
      error: false,
      name: "",
      email: "",
      textarea: "",
      nameRules: [(v) => !!v || this.$t("contact.ruleName")],
      emailRules: [(v) => !!v || this.$t("contact.ruleEmail"), (v) => /.+@.+\..+/.test(v) || this.$t("contact.ruleEmail2")],
      textRules: [(v) => !!v || this.$t("contact.ruleText"), (v) => (v && v.length >= 20) || this.$t("contact.ruleText2")],
      checkbox: false,
    };
  },
  methods: {

    clearContact() {
      this.name = "";
      this.email = "";
      this.textarea = "";
    },
    async validate() {
      this.loading = true;
      const { valid } = await this.$refs.form.validate();
      this.valid = valid;

      if (!this.valid) {
        this.loading = false;
        return;
      }

      let data = {
        name: this.name,
        email: this.email,
        text: this.textarea,
      };

      this.sent = false;
      this.error = false;

      fetch("/form", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      }).then((response) => {
        if (response.status != 200) {
          this.error = true;
          this.loading = false;
        } else {
          this.sent = true;
          this.loading = false;
          this.clearContact();

        }
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped>
.content-enter-active {
  transition: opacity 1s ease;
}

.content-leave-active {
  transition: opacity 1s ease;
}

.content-enter-from {
  opacity: 0;
}

.content-leave-to {
  opacity: 0;
}

.lazyMobileTitle {
  color: #eee;
  font-family: "Audiowide", sans-serif;
  font-size: 2em;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.lazyMobileTitle2 {
  color: #eee;
  font-family: "Audiowide", sans-serif;
  font-size: 3.1vw;
  padding-bottom: 2em;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.lazyMobileTitle3 {
  color: #eee;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.line {
  width: 100%;
  position: relative;
  font-weight: var(--font-semi);
  padding: 0.1vw 0.1vw;
  border-radius: 0.5rem;
  height: 1px;
  margin-top: 3vw;
  background: #a6e59b;
  margin-bottom: 0.5vh;
}

.sendButton {
  display: flex;
  margin-top: 0.8vh;
}

.sendButtonMobile {
  display: flex;
  margin-top: 0em;
}

.textme {
  color: #eee;
  font-size: 3.1vw;
  padding-bottom: 0.2em;
  font-family: "Audiowide", sans-serif;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.otherContacts {
  color: rgba(255, 255, 255, 0.548);
  margin-top: 10%;
}

.subLinks {
  padding-bottom: 1em;
}

.form {
  position: fixed;
  left: 60%;
  top: 8%;
  width: 45vh;
  height: 85vh;
  padding-right: 2vw;
  overflow-y:auto;
  scrollbar-color: #a6e59b #eee;   /* scroll thumb and track */ 
  color: #eee;
}

.formMobile {
  position: fixed;
  left: 10%;
  top: 5%;
  width: 80%;
  color: #eee;
}
</style>
