<template>
  <Transition name="header">
    <div v-if="semeaphores.skillsEarly" :class="isMobile ? 'headerMobile' : 'header'">{{ $t("skills.title")}}</div>
  </Transition>

  <Transition name="header">

  <div v-if="semeaphores.skills" :class="isMobile ? 'skill-containerMobile' : 'skill-container'">
    <div style="display: flex; width: 100%">
      <div :class="isMobile ? 'skill-colMobile' : 'skill-col'">
        <Transition name="topcol" appear>
          <h1 v-if="semeaphores.skills" :class="isMobile ? 'topcolMobile' : 'topcol'">Frontend</h1>
        </Transition>
        <logo :display="semeaphores.skills" title="Vite" src="/logos/frontend/vite.png" :isMobile="isMobile"></logo>
        <logo :display="semeaphores.skills" title="VueJs" icon="vuejs" :inverted="true" :isMobile="isMobile"></logo>
        <logo :display="semeaphores.skills" title="Webpack" icon="webpack" :isMobile="isMobile"></logo>

        <logo
          :display="semeaphores.skills"
          title="ThreeJs"
          src="/logos/frontend/threejs.png"
          :inverted="true"
          :invertedColor="true"
          :isMobile="isMobile"
        ></logo>
        <logo :display="semeaphores.skills" title="Javascript" icon="language-javascript" :inverted="true" :isMobile="isMobile"></logo>
        <logo :display="semeaphores.skills" title="css" icon="language-css3" :isMobile="isMobile"></logo>

        <logo :display="semeaphores.skills" title="Wordpress" icon="wordpress" :inverted="true" :isMobile="isMobile"></logo>
        <logo :display="semeaphores.skills" title="Puppeteer" src="/logos/frontend/puppet.png" :isMobile="isMobile"></logo>
      </div>
      <div class="skill-col">
        <Transition name="topcol">
          <h1 v-if="semeaphores.skills" :class="isMobile ? 'topcolMobile' : 'topcol'">Backend</h1>
        </Transition>

        <logo :display="semeaphores.skills" title="NodeJs" icon="nodejs" :inverted="true" :isMobile="isMobile"></logo>
        <logo :display="semeaphores.skills" title="ExpressJs" icon="server-network" :inverted="true" :isMobile="isMobile"></logo>

        <logo :display="semeaphores.skills" title="Sql" icon="database" :isMobile="isMobile"></logo>
        <logo :display="semeaphores.skills" title="Baas" icon="router-network" :inverted="true" :isMobile="isMobile"></logo>

        <logo :display="semeaphores.skills" title="Nginx" icon="router-network" :inverted="true" :isMobile="isMobile"></logo>
        <logo :display="semeaphores.skills" title="Apache2" icon="router-network" :invertedColor="true" :isMobile="isMobile"></logo>

        <logo :display="semeaphores.skills" title="Ubuntu" icon="ubuntu"></logo>
        <logo :display="semeaphores.skills" title="Vitest" icon="wrench" :inverted="true" :isMobile="isMobile"></logo>
      </div>

      <div class="skill-col">
        <Transition name="topcol">
          <h1 v-if="semeaphores.skills" :class="isMobile ? 'topcolMobile' : 'topcol'">{{ $t("skills.other")}}</h1>
        </Transition>
        <logo :display="semeaphores.skills" title="DevOps" icon="devices" :inverted="true" :isMobile="isMobile"></logo>
        <logo :display="semeaphores.skills" title="Python" icon="language-python" :isMobile="isMobile"></logo>
        <logo :display="semeaphores.skills" title="" icon="language-cpp" :inverted="true" :isMobile="isMobile"></logo>
        <logo :display="semeaphores.skills" title="" icon="language-csharp" :inverted="true" :isMobile="isMobile"></logo>
        <logo :display="semeaphores.skills" title="OpenGL" src="/logos/backend/python.png" :inverted="true" :isMobile="isMobile"></logo>
        <logo :display="semeaphores.skills" :title="$t('skills.teamlead')" icon="account-group" :isMobile="isMobile"></logo>
        <logo :display="semeaphores.skills" title="Scrum" icon="coffee"></logo>
        <logo :display="semeaphores.skills" title="GIT" icon="git"></logo>

      </div>
    </div>

  </div>
</Transition>

</template>

<script>
import Logo from "./Logo.vue";

export default {
  name: "Skills",
  components: {
    Logo,
  },
  props: {
    semeaphores: Object,
    isMobile: Boolean,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.whitefont {
  color: #fafafa;
}

.topcol {
  color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 0.3em;
}

.topcolMobile {
  color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 5vw;
}

.header {
  position: fixed;
  left: 18%;
  top: 2.3vh;
  color: #eee;
  font-family: "Audiowide", sans-serif;
  font-size: 5.1vw;
  transform: perspective(400px) rotateY(3deg) rotateZ(2deg);
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.headerMobile {
  position: fixed;
  left: 12%;
  top: 67%;
  color: #eee;
  font-family: "Audiowide", sans-serif;
  font-size: 18.1vw;
  color: #fafafa;
  transform: perspective(400px) rotateY(-3deg) rotateZ(-8deg);
}

.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.topcol-enter-active,
.topcol-leave-active {
  transition: opacity 1s ease;
}

.topcol-enter-from,
.topcol-leave-to {
  opacity: 0;
}

.header-enter-active,
.header-leave-active {
  transition: opacity 1s ease;
}

.header-enter-from,
.header-leave-to {
  opacity: 0;
}

.skill-container {
  background: #caf0c342;
  border-radius: 25px;
  position: fixed;
  display: flex;
  width: 50%;
  left: 19%;
  top: 18vh; 
  height: 75%;
  max-height: 75%;
  transform: perspective(400px) rotateY(3deg);
}

.skill-containerMobile {
  background: #caf0c3;
  position: fixed;
  display: flex;
  width: 94%;
  left: 3%;
  top: 4%;
}

.skill-col {
  position: relative;
  width: 100%;
}

.skill-colMobile {
  position: relative;
  width: 30%;
}
</style>
