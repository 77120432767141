
import {
    PlaneGeometry,
    ShaderMaterial,
    Vector2,
    Points,
    Mesh,
    TextureLoader,
    BoxGeometry,
    CubeCamera,
    BufferAttribute,
    Vector3,
    Color,
    AdditiveBlending,
    Scene,
    MeshBasicMaterial,
} from "three";




import vertex from "./GLS/vertexRobin.glsl";
import fragment from "./GLS/fragmentRobin.glsl";

class Robin {

    constructor(settings, scene, loop,) {
        let t = "/projects/absrob.png";
        const texture = new TextureLoader().load(t);
        let mesh = new PlaneGeometry(8, 12, 200, 200);

        // simple red material

        let material = new ShaderMaterial({
            uniforms: {
                time: { value: 0. },
                resolution: { value: new Vector2() },
                // t: {type : "t", value : new TextureLoader().load(t)},
                distortion: { type: "f", value: 1. },
                //velocity: {type:"vec3", value:[1,2,3]},
                uTexture: { type: "t", value: texture },
                progressY: { type: "f", value: 0 },

            },
            // add shaders
            vertexShader: vertex,
            fragmentShader: fragment,

            // add other material properties
            depthTest: false,
            transparent: true,
            depthWrite: false,
            // blending: AdditiveBlending,


        });



        let random = new Float32Array(mesh.attributes.position.array.length / 3);
        let colorRandom = new Float32Array(mesh.attributes.position.array.length / 3);

        for (let i = 0; i < random.length; i++) {
            random[i] = Math.random();
            colorRandom[i] = Math.random();
        }

        mesh.setAttribute("aRandom", new BufferAttribute(random, 1));
        mesh.setAttribute("aColorRandom", new BufferAttribute(colorRandom, 1));


        // apply material to mesh

        // scale mesh

        // mesh = new BoxGeometry(10,10, 10, 100, 100, 100)
        //material = new MeshBasicMaterial({ color: 0x00ff00 });
        let pixel = new Points(mesh, material);

        // add mesh to scene
        pixel.tick = (delta) => {
            material.uniforms.time.value += delta;



            // jumps after menu click
            if(Math.abs(material.uniforms.progressY.value - this.scrollLevel) > 0.5  && this.scrollLevel> 0.66) {
                material.uniforms.progressY.value = 0.66;
            }

            let progressYDelta = delta / 5 ;

            if (material.uniforms.progressY.value > this.scrollLevel) {
                material.uniforms.progressY.value -= progressYDelta;
            } else if (material.uniforms.progressY.value < this.scrollLevel) {
                material.uniforms.progressY.value += progressYDelta;
            }

            if (Math.abs(material.uniforms.progressY.value - this.scrollLevel) < 0.01) {
                material.uniforms.progressY.value = this.scrollLevel;

            }




            // material.uniforms.distortion.value = settings.distortion;


        };

        loop.updatables.push(pixel);
        scene.add(pixel)
        // scene.add(pixel);

    }

    setScrollLevel(scrollLevel) {
        this.scrollLevel = scrollLevel;
    }


}



export { Robin };

