import { Clock } from "three";
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';
// import Stats from 'stats.js'

const clock = new Clock();

class Loop {
  constructor(camera, camera2, scene, scene2, renderer) {
    this.camera = camera;
    this.camera2 = camera2;
    this.scene = scene;
    this.scene2 = scene2;
    this.renderer = renderer;
    this.updatables = [];

    // this.stats = new Stats();
    // console.log(this.stats)
    // this.stats.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom
    // document.body.appendChild(this.stats.dom)
  }

  start() {
    this.renderer.setAnimationLoop(() => {
      this.tick();
      this.renderer.autoClear = false;
      this.renderer.clear();
      this.renderer.render(this.scene2, this.camera2);
      this.renderer.render(this.scene, this.camera,);
      TWEEN.update()
    });
  }

  stop() {
    this.renderer.setAnimationLoop(null);
  }

  tick() {
    const delta = clock.getDelta();
    // this.stats.begin()
    for (const object of this.updatables) {
      object.tick(delta);
    }
    // this.stats.end()
  }
}

export { Loop };
