import { DirectionalLight, DirectionalLightHelper } from "three";

function createLights(color) {
  const light = new DirectionalLight(color, 40);
  const lightHelper = new DirectionalLightHelper(light, 0);
  light.position.set(0, 55, 55);

  light.tick = (delta) => {
    if (delta) {
      () => {};
    }
  };

  return { light, lightHelper };
}

export { createLights };
