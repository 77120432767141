<template>
  <div class="kleber">
    <button
      v-if="!backendEnter && semeaphores.skills"
      class="button"
      @click="
        () => {
          backendEnter = true;
          $emit('activeBackend');
          request();
        }
      "
    >
      {{ $t("skills.backendshow") }}
    </button>

    <div v-if="backendEnter && semeaphores.skills">
      <api-timer name="Server USA" :timer="times.ffm" class="timeA" :loading="loading[0]">{{ times.own }}</api-timer>
      <api-timer name="Server Emirate" :timer="times.london" class="timeB" :loading="loading[1]">{{ times.aws }}</api-timer>
      <api-timer name="Server Frankfurt" :timer="times.usa" class="timeC" :loading="loading[2]">{{ times.azure }}</api-timer>
      <api-timer name="Server UK" :timer="times.dubai" class="timeD" :loading="loading[3]">{{ times.blabla }}</api-timer>
    </div>
  </div>
</template>

<script>
import ApiTimer from "./ApiTimer.vue";

export default {
  name: "BackendShow",
  components: {
    ApiTimer,
  },
  props: {
    semeaphores: Object,
  },
  data() {
    return {
      backendEnter: false,
      loading: [false, false, false, false],
      times: {
        ffm: 0,
        london: 0,
        usa: 0,
        dubai: 0,
      },
      azureLinks: {
        ffm: "https://XX.blob.core.windows.net/public/latency-test.json",
        london: "https://XX.blob.core.windows.net/public/latency-test.json",
        usa: "https://XX.blob.core.windows.net/public/latency-test.json",
        dubai: "https://XX.blob.core.windows.net/public/latency-test.json",
      },
    };
  },


  methods: {
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },

    ping(target, callback) {
      fetch(this.azureLinks[target])
        .then((response) => {
          console.log(response);
          callback();
        })
        .catch(() => {
          callback();
        });
    },
    request() {
      this.times = {
        ffm: 0,
        london: 0,
        usa: 0,
        dubai: 0,
      };

      this.$emit("startRequest");

      let timer = Date.now();

      setTimeout(() => {
        console.log("reset button");
        this.backendEnter = false;
      }, 10000);

      this.loading = [true, true, true, true];

      setTimeout(() => {
        this.ping("ffm", () => {
          this.loading[0] = false;
          this.times.ffm = Date.now() - timer;
          setTimeout(() => {
            this.$emit("finishRequest", 0), this.getRandomInt(500);
          });
        });
      }, this.getRandomInt(750));

      setTimeout(() => {
        this.ping("london", () => {
          this.loading[1] = false;
          this.times.london = Date.now() - timer;
          setTimeout(() => {
            this.$emit("finishRequest", 1), this.getRandomInt(500);
          });
        });
      }, this.getRandomInt(750));

      setTimeout(() => {
        this.ping("usa", () => {
          this.loading[2] = false;
          this.times.usa = Date.now() - timer;
          setTimeout(() => {
            this.$emit("finishRequest", 2), this.getRandomInt(500);
          });
        });
      }, this.getRandomInt(750));

      setTimeout(() => {
        this.ping("dubai", () => {
          this.loading[3] = false;

          this.times.dubai = Date.now() - timer;
          setTimeout(() => {
            this.$emit("finishRequest", 3), this.getRandomInt(500);
          });
        });
      }, this.getRandomInt(750));
    },
  },
};
</script>

<style scoped>
.timeA {
  position: absolute;
  transform: translateX(-0%) translateY(+18%);
}

.timeB {
  position: absolute;
  transform: translateX(+20%) translateY(192%);
}

.timeC {
  position: absolute;
  transform: translateX(+20%) translateY(-67%);
}

.timeD {
  position: absolute;
  transform: translateX(-20%) translateY(-180%);
}

.kleber {
  position: fixed;
  width: 20%;
  left: 50%;
  top: 50%;
  animation: superman-fly-right ease-in-out 62.831s infinite;
}

@keyframes superman-fly-right {
  0% {
    transform: translateX(90%) translateY(9vw);
  }
  50% {
    transform: translateX(160%) translateY(11vw);
  }
  100% {
    transform: translateX(90%) translateY(9vw);
  }
}

.button {
  transition: all 0.5s ease;
  border: 3px solid #7dda71;
  color: #eee;
  background-color: #006d0075;
  padding: 10px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  width: 23vh;
  position: fixed;
  transform: translate(-50%, -50%);
}
</style>
