<template>
  <div :class="landing ? 'main' : 'mainAuto'">
    <!-- <button @click="world.updateUpdateProgress(this.percentage)" style="z-index:10000;">GO</button> -->

    <div v-if="landing">
      <Title :semaphores="semeaphores" :isMobile="isMobile"></Title>

      <Menu
        v-if="!isMobile"
        :semeaphores="semeaphores"
        :percentage="percentage"
        :isMobile="isMobile"
        @start="scrollTo(0.0)"
        @backend="scrollTo(0.33)"
        @frontend="scrollTo(0.66)"
        @contact="scrollTo(1)"
      ></Menu>

      <mouse-scroll-icon v-if="!isMobile" :semeaphores="semeaphores" :isMobile="isMobile" class="scrollIcon"></mouse-scroll-icon>

      <skills v-if="!isMobile" :semeaphores="semeaphores" :isMobile="isMobile"> </skills>

      <backend-show
        v-if="!isMobile && false"
        :semeaphores="semeaphores"
        @activeBackend="(backend) => world.setBackend(true)"
        @startRequest="() => world.startRequest()"
        @finishRequest="(ix) => world.finishRequest(ix)"
      ></backend-show>

      <work
        v-if="!isMobile"
        :semeaphores="semeaphores"
        :index="frontEndIndex"
        @workLeft="
          () => {
            frontEndIndex = world.workLeft();
          }
        "
        @workRight="
          () => {
            frontEndIndex = world.workRight();
          }
        "
      >
      </work>

      <contact-form :semeaphores="semeaphores" :isMobile="isMobile"></contact-form>
    </div>

    <div v-if="gpdr">
      <gpdr
        :isMobile="isMobile"
        @reset="
          () => {
            resetSimpleRouter();
          }
        "
      ></gpdr>
    </div>
    <div v-if="legal">
      <legal
        :isMobile="isMobile"
        @reset="
          () => {
            resetSimpleRouter();
          }
        "
      ></legal>
    </div>

    <div class="canvas" id="scene-container"></div>

    <legal-footer
      :semeaphores="semeaphores"
      @refresh="
        (target) => {
          simpleRouting(target);
        }
      "
    ></legal-footer>
    <multi-language v-if="!isMobile" :semeaphores="semeaphores"></multi-language>
  </div>
</template>

<script>
import { World } from "./World/World.js";
import Title from "./components/Title.vue";
import Menu from "./components/Menu.vue";
import MouseScrollIcon from "./components/MouseScrollIcon.vue";
import BackendShow from "./components/BackendShow.vue";
import Work from "./components/Work.vue";
import ContactForm from "./components/ContactForm.vue";
import Skills from "./components/Skills.vue";
import LegalFooter from "./components/Footer.vue";
import MultiLanguage from "./components/MultiLanguage.vue";
import Gpdr from "./components/Gpdr.vue";
import Legal from "./components/Legal.vue";

export default {
  name: "App",
  components: {
    Title,
    Menu,
    MouseScrollIcon,
    BackendShow,
    Work,
    ContactForm,
    Skills,
    LegalFooter,
    MultiLanguage,
    Gpdr,
    Legal,
  },
  emits: {
    buttonFrontendLeft: null,
  },

  data() {
    return {
      world: undefined,
      percentage: 0,
      frontEndIndex: 0,
      scrollWatchDog: undefined,
      forcedScroll: false,
      scrollDelta: 0.1,
      smartTimeOutRunning: false,
      isMobile: false,
      smartTimeOut: undefined,
      landing: true,
      gpdr: false,
      legal: false,

      semeaphores: {
        scrolled: false,
        start: false,
        startEarlyEnd: false,
        skillsEarly: false,
        skills: false,
        skillEarlyEnd: false,
        workEarly: false,
        work: false,
        workEarlyEnd: false,
        contactEarly: false,
        contact: false,
      },
    };
  },

  mounted() {
    let container = document.querySelector("#scene-container");
    // 1. Create an instance of the World app
    this.scrollTo(0);

    this.simpleRouting("");
    this.isMobile = window.innerWidth < 600;
    this.world = new World(container, this.scrollDelta, this.isMobile);
    this.updateWorldProgress();
    this.updateSemaphores();
    this.onResize();
  },

  created() {
    // custom hooks
    window.addEventListener("scroll", this.onScroll);

    window.addEventListener("resize", this.onResize, { passive: true });

    // window.addEventListener("wheel", this.onScroll);
  },

  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
    // argue with hot reload
    this.world = new World(container);
  },

  methods: {
    // default settTimout but only one can be active a the same time
    startStatTimeOut(callback, time) {
      // no timeout is running, this is the first one
      if (!this.smartTimeOutRunning) {
        this.smartTimeOutRunning = true;
        this.smartTimeOut = setTimeout(() => {
          callback();
          this.endSmartTimeOut();
        }, time);
      } else {
        // time out is running, overwrite it
        clearTimeout(this.smartTimeOut);
        this.smartTimeOut = setTimeout(() => {
          callback();
          this.endSmartTimeOut();
        }, time);
      }
    },

    resetSimpleRouter() {
      history.replaceState({}, document.title, window.location.href.split("#")[0]);
      this.simpleRouting("");
    },
    simpleRouting(target) {
      let path = location.hash;

      if (path == "#gpdr" || target == "gpdr") {
        this.gpdr = true;
        this.landing = false;
        this.legal = false;
      } else if (path == "#legal" || target == "legal") {
        this.legal = true;
        this.landing = false;
        this.gpdr = false;
      } else {
        this.landing = true;
        this.gpdr = false;
        this.legal = false;
      }

      
      setTimeout(() => {
        this.isMobile ? this.scrollTo(1) : this.scrollTo(0);
        
      }, 50);
    },

    // and the single timeout
    endSmartTimeOut() {
      clearTimeout(this.smartTimeOut);
      this.smartTimeOutRunning = false;
    },

    // if scrolled not on the specific Y values, fix it. This is needed trough scrollytelling & 3D Scenes
    correctScroll() {
      if (this.percentage > 0 && this.percentage < 0.33 / 2) {
        this.scrollTo(0);
      } else if (this.percentage >= 0.33 / 2 && this.percentage < 0.33) {
        this.scrollTo(0.33);
      } else if (this.percentage >= 0.33 && this.percentage < 0.33 + 0.33 / 2) {
        this.scrollTo(0.33);
      } else if (this.percentage >= 0.33 + 0.33 / 2 && this.percentage < 0.66) {
        this.scrollTo(0.66);
      } else if (this.percentage >= 0.66 && this.percentage < 0.66 + 0.33 / 2) {
        this.scrollTo(0.66);
      } else if (this.percentage >= 0.66 + 0.33 / 2 && this.percentage < 1) {
        this.scrollTo(1);
      }
    },

    // handly relative scroll call
    scrollTo(percentage) {
      let pixelTarget = percentage * (document.documentElement.scrollHeight - document.documentElement.clientHeight);

      window.scrollTo(0, Math.max(0, pixelTarget));
      this.forcedScroll = true;
      this.updateWorldProgress();
    },

    // update 3D scenes in relation to scroll values
    updateWorldProgress() {
      if (this.world !== undefined) {
        this.world.updateUpdateProgress(this.percentage, this.semeaphores);
      }
    },

    // function to decides which element are active
    updateSemaphores() {
      // 1. deactive everthing, then enable the wanted ones
      this.semeaphores.scrolled = false;
      this.semeaphores.start = false;
      this.semeaphores.startEarly = false;
      this.semeaphores.skills = false;
      this.semeaphores.skillsEarly = false;
      this.semeaphores.work = false;
      this.semeaphores.workEarly = false;
      this.semeaphores.contact = false;
      this.semeaphores.contactEarly = false;

      let percentage = this.percentage;
      isNaN(this.percentage) ? (percentage = 0) : () => {};

      if (percentage > 0) {
        this.semeaphores.scrolled = true;
      }

      if (percentage <= 0 + this.scrollDelta / 2) {
        this.semeaphores.start = true;
      }

      if (percentage <= 0 + this.scrollDelta) {
        this.semeaphores.startEarly = true;
      }

      if (percentage > 0.33 - this.scrollDelta / 2 && percentage < 0.33 + this.scrollDelta / 2) {
        this.semeaphores.skills = true;
      }

      if (percentage > 0.33 - this.scrollDelta && percentage < 0.33 + this.scrollDelta) {
        this.semeaphores.skillsEarly = true;
      }

      if (percentage > 0.66 - this.scrollDelta / 2 && percentage < 0.66 + this.scrollDelta / 2) {
        this.semeaphores.work = true;
      }

      if (percentage > 0.66 - this.scrollDelta && percentage < 0.66 + this.scrollDelta) {
        this.semeaphores.workEarly = true;
      }

      if (percentage > 1 - this.scrollDelta / 2) {
        this.semeaphores.contact = true;
      }

      if (percentage > 1 - this.scrollDelta) {
        this.semeaphores.contactEarly = true;
      }
    },

    // when users scrolls, get relative (procentual) representation
    onScroll(e) {
      this.startStatTimeOut(this.correctScroll, 1000);


      if (!this.landing && this.percentage == 0) {
        return;
      }

      // deactivate mobile scrolling on landing page
      if (this.isMobile && this.landing) {
        this.scrollTo(1);
      }

      this.percentage = window.scrollY / (document.documentElement.scrollHeight - document.documentElement.clientHeight);

      // update everthing accordingly
      this.updateSemaphores();
      this.updateWorldProgress();
    },

    onResize() {
      this.isMobile = window.innerWidth < 600;
      this.world.setIsMobile(this.isMobile);
    },
  },
};
</script>

<style>
@font-face {
  font-family: "Audiowide";
  src: url("~@/assets/fonts/Audiowide-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: url("~@/assets/fonts/Roboto-Regular.ttf") format("truetype");
}

.main {
  width: 100%;
  height: 6000px;
}

.mainAuto {
  width: 100%;
  height: auto;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

.canvas {
  width: 100%;
  height: 100%;

  position: fixed;
  background-color: rgb(1, 1, 8);

  top: 0;
  left: 0;
  z-index: -10;
}

#body {
  /*remove horizontal margins*/
  margin-left: 0;
  margin-right: 0;
  overflow-y: hidden;
  height: 100vh;
}

a {
  color:#eee;
}
a:hover {
  color:#a6e59b;
  text-decoration: none;
}
</style>
