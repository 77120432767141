<template>
  <!-- transparent rounded div container -->
  <Transition>
    <div v-if="semeaphores.contact" class="footer">
      <a href="#gpdr" style="text-decoration: none" @click="$emit('refresh', 'gpdr')"
        ><p class="whitefont">{{ $t("footer.gpdr")}}</p>
      </a>
      <p style="margin-left: 10px; margin-right: 10px">|</p>
      <a href="#legal" style="text-decoration: none"  @click="$emit('refresh', 'legal')">
        <p class="whitefont">{{ $t("footer.legal")}}</p>
      </a>
    </div>
  </Transition>
</template>

<script>
export default {
  name: "LegalFooter",
  props: { semeaphores: Object },
  data() {
    return {
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.whitefont {
  color: rgba(255, 255, 255, 0.308);
}
.whitefont:hover {
  color: rgba(255, 255, 255, 0.664);
}


.footer {
  position: fixed;
  display: flex;
  left: 50%;
  top: 95%;
  transform: translate(-50%, 0%);
  font-size: smaller;
  /* -moz-border-radius:10px;
  -webkit-border-radius:10px;
  border-radius:10px;
  background-color: rgba(255,255,255,0.2); 
  border-color: rgba(255,255,255,0.2);*/
}

.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
