<template>
  <div v-if="!isMobile">
    <Transition>
      <div v-if="semeaphores.start" class="scroll-downs">
        <div class="mousey">
          <div class="scroller"></div>
        </div>
      </div>
    </Transition>
  </div>
  <div v-else>
    <div class="scroll-downs-mobile">
      <v-icon class="scrollerMobile" size="300%" color="#EEE">mdi-cursor-pointer</v-icon>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "MouseScrollIcon",

  props: {
    semeaphores: Object,
    isMobile: Boolean,
  },
  data() {
    return {};
  },
};
</script>
    
<style scoped>
/* THIS CSS IS FROM https://codepen.io/jonairl/pen/MWvXgz and under MIT trough CODEPEN */
body,
html {
  background-color: #000;
  width: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
}
.scroll-downs-mobile {
  position: absolute;
  height: 70px;
  left:35%;
  top:80%;
}

.scroll-downs {
  position: absolute;
  top: 90%;
  right: 0;
  bottom: 0;
  left: 0%;
  margin: auto;
  width: 34px;
  height: 55px;
}


.mousey {
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid #EEE;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #29c929;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}
.scrollerMobile {
  height: 20%;
  animation-name: scrollMobile;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}

@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}

@keyframes scrollMobile {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-35px);
    opacity: 0;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
    