
import {
  ShaderMaterial,
  Vector2,
  Points,
  BufferAttribute,
  Color,
  AdditiveBlending,
  SphereGeometry,
  TextureLoader,
  Mesh,
  Raycaster,
  IcosahedronGeometry,
  CubicBezierCurve3,
  Vector3,
  BufferGeometry,
  LineBasicMaterial,
  Line,
} from "three";




// import gltf file


import vertex from "./GLS/vertexWorld.glsl";
import fragment from "./GLS/fragmentWorld.glsl";

class DDDWorld {
  constructor(settings, scene, loop, particleStart, camera) {
    this.mesh = new IcosahedronGeometry(55, 100, 100);

    this.mesh.translate(0, 0, 0);
  
    const texture = new TextureLoader().load('world.png');
  
    // rotate by 90 degrees
    this.mesh.rotateX(Math.PI / 2);
  
    this.lockedtime = 0;
    this.away = 2.;


    this.percentage = 0;

    // move to the hand
    // mesh.translate(4.5, 0, 1.9);
    // load texture
    // let t = require(".//texture.png");
    // simple red material
    this.material = new ShaderMaterial({
      uniforms: {
        time: { value: 0. },
        rotation: { value: 0. },
        resolution: { value: new Vector2() },
        texture1: { type: "t", value: texture },
        distortion: { type: "f", value: 0 },
        speed: { type: "f", value: 0 },
        //velocity: {type:"vec3", value:[1,2,3]},
        percentage: { type: "f", value: 0 },
        uSize: { type: "f", value: 1 },
        morphTargetBaseInfluence: { type: "f", value: 0 },
        mouseX: { type: "f", value: 0 },
        mouseY: { type: "f", value: 0 },
        avoidance: { type: "f", value: 0 },

        away: { type: "f", value: this.away },
        backend: { type: "b", value: false },

        uColor1: { value: new Color(0xF0FFF0) },
        uColor2: { value: new Color(0xF0FFF0) },
        uColor3: { value: new Color(0xF0FFF0) },
      },
      // add shaders
      vertexShader: vertex,
      fragmentShader: fragment,
  
      // add other material properties
      depthTest: true,
      transparent: false,
      depthWrite: true,
      // wireframe: true,
  
    });
 
  
    let random = new Float32Array(this.mesh.attributes.position.array.length / 3);
    let colorRandom = new Float32Array(this.mesh.attributes.position.array.length / 3);
    let index = new Float32Array(this.mesh.attributes.position.array.length / 3)
    for (let i = 0; i < random.length; i++) {
      random[i] = Math.random();
      colorRandom[i] = Math.random();
      index[i] = i;
    }
  
    this.mesh.setAttribute("aRandom", new BufferAttribute(random, 1));
    this.mesh.setAttribute("aColorRandom", new BufferAttribute(colorRandom, 1));
    this.mesh.setAttribute("aIndex", new BufferAttribute(index, 1));
  
    
    
  
    // apply material to mesh
  
    // scale mesh
  
    // mesh = new BoxGeometry(10,10, 10, 100, 100, 100)
    //material = new MeshBasicMaterial({ color: 0x00ff00 });
    this.pixel = new Mesh(this.mesh, this.material);
  
    // roate ny 90 degrees
  
    this.pixel.rotateX(- Math.PI / 2);
  
    // pixel.translateY(particleStart.pos.x);
    // pixel.translateX(particleStart.pos.y);
    // pixel.translateZ(particleStart.pos.z);
  
  
  
    let mouse = new Vector2();
    window.addEventListener("mousemove", (e) => {
      mouse.x = e.clientX /  window.innerWidth ;
      mouse.y = e.clientY / window.innerHeight ;
    });
  
  

    
  
    this.pixel.tick = (delta) => {
      this.material.uniforms.time.value += delta;
      this.material.uniforms.rotation.value = this.rotation;

      this.material.uniforms.distortion.value = settings.distortion;
      this.material.uniforms.speed.value = settings.speed;
      this.material.uniforms.percentage.value = this.percentage;
  
      // update mouse postion in shader
      this.material.uniforms.mouseX.value = mouse.x;
      this.material.uniforms.mouseY.value = mouse.y;
      this.material.uniforms.avoidance.value = settings.avoidance;
      this.material.uniforms.uSize.value = settings.uSize;
      // console.log(mouse)
  
      this.material.uniforms.uColor1.value = settings.color1;
      this.material.uniforms.uColor2.value = settings.color2;
      this.material.uniforms.uColor3.value = settings.color3;
      this.material.uniforms.away.value = this.away;
      this.material.uniforms.backend.value = this.backend;
    };
  





    loop.updatables.push(this.pixel);
  
    //  pixel.scale.set( 0.01, 0.01, 0.01 );
    scene.add(this.pixel)
  }
  // new sphere object
  
  // scene.add(pixel);


  updatePostion(away) {
    this.away = away;
  }



  setBackend(backend) {
    this.rotation = this.material.uniforms.time.value;
    this.backend = backend;
  }



}


export {DDDWorld};








